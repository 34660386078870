<div fxLayout="column">
    <form [formGroup]="orderForm" class="p-24"
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="start center"
          fxLayoutAlign.lt-md="start"
          fxLayoutGap="15px">

        <mat-form-field appearance="outline" color="primary" fxFlex="40"
                        fxFlex.lt-md="1 1 80">
            <mat-label>Status</mat-label>

            <mat-select formControlName="orderStatus" required>
                <mat-option *ngFor="let status of orderStatuses | async"
                            [value]="status.id">
                    {{status?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button (click)="updateOrderStatus()"
                [disabled]="!orderForm.get('orderStatus').dirty" color="accent"
                fxFlex.lt-md="1 1 20"
                mat-raised-button>
            Update Status
        </button>
    </form>


    <div style="overflow:auto">
        <table class="simple" fxFlex="1 1 70" fxFlex.lt-lg="0 0 100"
               fxFlex.lt-sm="0 0 250">
            <thead>
            <tr>
                <th>Status</th>
                <th>User</th>
                <th>Date</th>
                <th>Note</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let statusChange of parsedStatusChanges">
                <tr>
                    <td style="height:auto ">
                        <span class="text-boxed">{{'ORDER.STATUS.' + statusChange?.orderStatus | translate}}
                        </span>
                    </td>
                    <td>

                        <span
                            class="text-truncate">{{ statusChange?.user | translate}}
                        </span>
                    </td>
                    <td>
                        <span
                            class="text-truncate">{{statusChange?.date | date : ('DateFormat.date' | translate) }}</span>
                    </td>
                    <td *ngIf="isExpandableStatusChange(statusChange)">

                        <mat-icon
                            (click)="statusChange.expanded = !statusChange.expanded"
                            *ngIf="!statusChange.expanded"
                            style="cursor: pointer">expand_more
                        </mat-icon>
                        <mat-icon
                            (click)="statusChange.expanded = !statusChange.expanded"
                            *ngIf="statusChange.expanded"
                            style="cursor: pointer">
                            expand_less
                        </mat-icon>
                        <!--  <span style="white-space: pre-line">{{statusChange?.notes}}</span>-->
                    </td>
                    <td *ngIf="!isExpandableStatusChange(statusChange)">
                        <span>{{statusChange?.notes}}</span>
                    </td>

                </tr>
                <tr *ngIf="statusChange?.orderStatus === orderStatusEnum.SENT_TO_YUSEN && statusChange.expanded">

                    <div class="p-36 mat-elevation-z4" fxFlex="1 1 auto"
                         fxLayout="column" fxLayoutGap="10px">
                        <div fxFlex>
                            <span class="accent-fg h2">Csv rows</span>
                        </div>
                        <div fxFlex>
                            <table
                                [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                                <thead>
                                <tr>
                                    <th *ngFor="let col of _orderDetailService.csvCols$ | async">{{col}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let val of statusChange?.parsedNotes">
                                    <td *ngFor="let entry of val">
                                        <span>{{entry}}</span>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>

                </tr>
                <tr *ngIf="statusChange?.orderStatus === orderStatusEnum.COMMISSIONED && statusChange.expanded">

                    <div class="p-36 mat-elevation-z4" fxFlex="1 1 auto"
                         fxLayout="column" fxLayoutGap="10px">
                        <div fxFlex>
                            <span class="accent-fg h2">Csv rows</span>
                        </div>
                        <div fxFlex>
                            <table
                                [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                                <thead>
                                <tr>
                                    <th *ngFor="let col of _orderDetailService.wavcCsvCols$ | async">{{col}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let val of statusChange?.parsedNotes">
                                    <td *ngFor="let entry of val">
                                        <span>{{entry}}</span>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </tr>
                <tr *ngIf="statusChange?.orderStatus === orderStatusEnum.SHIPPING_CONFIRMED && statusChange.expanded">
                    <div class="p-36 mat-elevation-z4" fxFlex="1 1 auto"
                         fxLayout="column" fxLayoutGap="10px">
                        <div fxFlex>
                            <span class="accent-fg h2">Csv rows</span>
                        </div>
                        <div fxFlex>
                            <table
                                [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                                <thead>
                                <tr>
                                    <th *ngFor="let col of _orderDetailService.obdcCsvCols$ | async">{{col}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let val of statusChange?.parsedNotes">
                                    <td *ngFor="let entry of val">
                                        <span>{{entry}}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </tr>
                <tr *ngIf="statusChange?.orderStatus === orderStatusEnum.SENT_TO_PDR && statusChange.expanded">
                    <div class="p-36 mat-elevation-z4" fxFlex="1 1 auto"
                         fxLayout="column" fxLayoutGap="10px">
                        <div fxFlex>
                            <span class="accent-fg h2">Csv rows</span>
                        </div>
                        <div fxFlex>
                            <table
                                [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                                <thead>
                                <tr>
                                    <th *ngFor="let col of _orderDetailService.csvToPdrCols$ | async">{{col}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let val of statusChange?.parsedNotes">
                                    <td *ngFor="let entry of val">
                                        <span>{{entry}}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>
</div>
