import { Injectable } from '@angular/core';
import { HttpService } from '../http/httpservice.service';
import { Observable } from 'rxjs';
import {ConfigService} from "@yukawa/chain-base-angular-client";
import {User} from "@yukawa/chain-main-angular-core";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userUrl: string = "";


  constructor(private configService: ConfigService, private http: HttpService) {
    this.userUrl = this.configService.getValue("userUrl");
  }

  loadUser(id: any): Observable<User> {
    return this.http.Get(`${this.userUrl}/${id}`);
  }

  editUser(user: User): Observable<User> {
    return this.http.Post(`${this.userUrl}`, user);

  }

  queryUsers(filter: any): Observable<any> {
    return this.http.Post(`${this.userUrl}`, filter);
  }

  deleteUser(id: string): Observable<any> {
    return this.http.Delete(`${this.userUrl}/${id}`);
  }

  updateUser(user: User): Observable<any> {
    return this.http.PUT(`${this.userUrl}`, user);
  }

  createUser(user: User): Observable<any> {
    return this.http.Post(`${this.userUrl}`, user);
  }

}
