<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="center center">

    <div id="login-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'30ms',scale:'0.1'}}">

        <div id="login-form">

            <div class="logo">
                <img src="assets/sturm_logo.svg" alt="logo">
            </div>

            <form name="loginForm" (ngSubmit)="login()" [formGroup]="loginForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>{{'LOGIN.USERNAME' | translate}}</mat-label>
                    <input matInput formControlName="username">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error *ngIf="loginForm.get('username').hasError('required')">
                        {{'LOGIN.USERNAME_REQUIRED' | translate}}
                    </mat-error>

                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'LOGIN.PASSWORD' | translate}}</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        {{'LOGIN.PASSWORD_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <!-- <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                    fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Remember Me
                    </mat-checkbox>

                    <a class="forgot-password" [routerLink]="'/pages/auth/forgot-password-2'">
                        Forgot Password?
                    </a>
                </div> -->

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN"
                        [disabled]="loginForm.invalid">
                    {{'LOGIN.TITLE' | translate}}
                </button>

            </form>

            <!--<div class="separator">
                <span class="text">OR</span>
            </div>
            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text"> {{'LOGIN.NO_ACCOUNT' | translate}}?</span>
                <a class="link" [routerLink]="['/userregister']">{{'LOGIN.CREATE_ACCOUNT' | translate}}</a>
            </div>-->

        </div>
    </div>
</div>