// auth.service.ts
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { User } from '@yukawa/chain-main-angular-core';
import { BehaviorSubject, Observable, of, Subscription, throwError, timer } from 'rxjs';
import { catchError, filter, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import { SturmRealm } from '../../../../sturm/base/core/realm';
import { NotificationService } from '../../../services/notification/notification.service';
import { UserService } from '../../../services/user/user.service';
import { AuthenticationChangeEvent, Token } from '../core/data';


export const LOGIN_USER_KEY = 'sturm_weave_loginuser';
export const ACCESS_TOKEN_KEY = 'sturm_weave_loginuser_accessToken';
export const ACCESS_TOKEN_EXPIRES_KEY = 'sturm_weave_loginuser_access_expires';
export const REFRESH_TOKEN_KEY = 'sturm_weave_refreshToken';

@Injectable()
export class AuthService {

    onAuthenticationChange: BehaviorSubject<AuthenticationChangeEvent>;
    loginUser: BehaviorSubject<User>;
    userRoles: any[];
    groups: string[];
    user: User;
    accessTokenExpiresSubscription: Subscription;

    constructor(
        private router: Router,
        protected http: HttpClient,
        private notificationService: NotificationService,
        private userService: UserService,
        private tokenService: TokenService,
        protected configService: ConfigService
    ) {

        this.init();
        this.loadUserOnAuthChange();
        this.listenForAccessTokenExpiry();

    }

    /**
     *
     * @private
     */
    private loadUserOnAuthChange() {
        this.onAuthenticationChange.pipe(mergeMap(value => {
            this.userRoles = value.roles;
            if (value.username) {
                return this.userService.loadUser(value.username);
            }
            return of({});
        })).subscribe(this.loginUser);
    }

    /**
     *
     * @private
     */
    private init() {
        this.onAuthenticationChange = new BehaviorSubject<any>({
            username: this.tokenService.token.username,
            event: 'init',
            roles: this.tokenService.token.scope
        } as AuthenticationChangeEvent);
        this.loginUser = new BehaviorSubject<any>({});
    }

    /**
     *
     * @private
     */
    private listenForAccessTokenExpiry() {
        this.accessTokenExpiresSubscription = this.tokenService.onAccessTokenExpired
            .pipe(
                mergeMap(() => {
                    if (this.isLoggedIn()) {
                        return this.refreshToken();
                    }
                    return of({} as Token);
                }),
                retry(2))
            .subscribe(val => {
                    if (this.isLoggedIn()) {
                        this.tokenService.setAccessToken(val);
                    }
                },
                () => {
                    this.logout();
                });
    }

    /**
     *
     * @param data
     */
    public loginForm(data): Observable<any> {
        return this.http
            .post(this.configService.formatUrl("securityUrl") + '/auth/token', data)
            .pipe(
                tap((token: Token) => {
                    this.tokenService.setToken(token);
                    this.onAuthenticationChange.next({event: 'login', username: token.username, roles: token.scope});
                }),
                retry(2),
                catchError(this.handleError)
            );
    }

    /**
     *
     * @private
     */
    private refreshToken(): Observable<Token> {
        const url = this.configService.formatUrl("securityUrl") + '/auth/refresh';
        return this.http.get<Token>(url);
    }

    /**
     *
     */
    public isLoggedIn(): boolean {
        return !this.tokenService.isAccessTokenExpired();
    }

    /**
     *
     */
    public logout() {
        this.tokenService.removeToken();
        this.onAuthenticationChange.next({event: 'logout'} as AuthenticationChangeEvent);
        this.router.navigate(['/login']);
    }

    /**
     *
     */
    getRoles() {
        return JSON.parse(localStorage.getItem(LOGIN_USER_KEY)).roles;
    }

    /**
     *
     */
    getUser(): User {
        return this.user;
    }

    // Handle API errors
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }

    hasRole(role: string): boolean {
        if (!this.isLoggedIn() || !this.userRoles) {
            return false;
        }
        return this.userRoles.indexOf(role) !== -1;
    }

    hasAnyRole(roles: string[]): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }
        const userRoles = this.getUserRoles();
        for (const item of roles) {

            if (userRoles.indexOf(item) !== -1) {
                return true;
            }
        }
        return false;
    }

    hasAnyRoleOrAdmin(roles: string []): boolean {
        return this.isAdmin() || this.hasAnyRole(roles);
    }

    hasAllRoles(roles: string[]) {
        return roles.every(role => this.getUserRoles().indexOf(role) >= 0);
    }

    getUserRoles() {
        return this.userRoles != null ? this.userRoles : [];
    }

    isAdmin(): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }
        const roles = this.getUserRoles();
        if (roles == null) {
            return false;
        }
        return roles.indexOf(SturmRealm.ROLE_ADMIN) !== -1;
    }

}

@Injectable(
    {providedIn: 'root'}
)
export class TokenService {

    onTokenChange: BehaviorSubject<Token>;
    onAccessTokenExpired: Observable<any>;
    expiresIn: number;
    token: Token;

    constructor(private _configService: ConfigService) {

        this.initializeTokenFromStorage();

        // emits when token is 1. removed, 2. queried 3. refreshed 4. initialized from local storage
        this.onTokenChange = new BehaviorSubject<Token>(this.token);

        // when the token is set, the expiresIn time is recalculated
        this.setExpiresInOnTokenChange();

        // Observable that notifies observers 20s before the access_token expires
        this.onAccessTokenExpired = this.onTokenChange.pipe(
            filter(val => {
                return val.access_token != null;
            }),
            switchMap(
                () => {
                    console.debug("Timer for auth expiration started");
                    return timer(this.expiresIn - 20000);
                }
            ));

    }

    /**
     * Some browsers cannot handle Dateformat dd-mm-yyyy. This function converts to
     * dd/mm/yyyy (Safari requires this format for some reason) and fixes timezone offset
     * @param date Date string that should be parsed
     */
    private static parseDateString(date: string): Date {
        let formattedDate = date.replace(/-/g, '/').toString();
        formattedDate = formattedDate.replace(/T/g, ' ');
        formattedDate = formattedDate.split('.')[0];

        const newDate = new Date(formattedDate);
        return new Date(newDate.getTime() - (new Date().getTimezoneOffset() * 60000));
    }

    /**
     *
     * @private
     */
    private setExpiresInOnTokenChange() {
        this.onTokenChange.subscribe(value => {
            this.token = value;
            if (this.token.access_token) {
                const milliseconds = new Date().getTime();
                const accessExpires = TokenService.parseDateString(this.token.access_expires.toString()).getTime();
                this.expiresIn = accessExpires - milliseconds;
                console.info("access token expires in " + this.expiresIn / 1000 / 60 + " minutes");
            }

        });
    }

    /**
     * inits the token from local storage
     */
    initializeTokenFromStorage() {
        this.token = {
            username: localStorage.getItem(LOGIN_USER_KEY) ? JSON.parse(localStorage.getItem(LOGIN_USER_KEY)).username : null,
            access_token: localStorage.getItem(ACCESS_TOKEN_KEY),
            refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY),
            access_expires: localStorage.getItem(ACCESS_TOKEN_EXPIRES_KEY),
            scope: JSON.parse(localStorage.getItem(LOGIN_USER_KEY))?.roles || []
        } as Token;
    }

    /**
     * Removes the login user, access token and refresh token from local storage
     */
    removeToken() {
        localStorage.removeItem(LOGIN_USER_KEY);
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        localStorage.removeItem(ACCESS_TOKEN_EXPIRES_KEY);
        this.onTokenChange.next({} as Token);
    }

    /**
     * Stores the provided token in local storage and notifies subject
     * @param token auth token
     */
    setToken(token: Token) {
        // this.removeToken();
        // localStorage.setItem(TOKEN_KEY);
        localStorage.setItem(ACCESS_TOKEN_KEY, token.access_token);

        if (token.refresh_token) {
            localStorage.setItem(REFRESH_TOKEN_KEY, token.refresh_token);
        }

        localStorage.setItem(LOGIN_USER_KEY, JSON.stringify({
            username: token.username,
            roles: token.scope
        }));
        localStorage.setItem(ACCESS_TOKEN_EXPIRES_KEY, token.access_expires);
        this.onTokenChange.next(token);
    }


    setAccessToken(token: Token) {
        localStorage.setItem(ACCESS_TOKEN_EXPIRES_KEY, token.access_expires);
        localStorage.setItem(ACCESS_TOKEN_KEY, token.access_token);

        this.onTokenChange.next({
            ...this.token,
            access_token: token.access_token,
            access_expires: token.access_expires,

        } as Token);

    }

    /**
     * Returns true if the access token is expired. This is the case if either the access_expires it not
     * set OR the access is expired
     */
    isAccessTokenExpired() {
        if (!this.token.access_expires) {
            return true;
        } else {
            const now = new Date().getTime();
            const accessExpires = TokenService.parseDateString(this.token.access_expires.toString()).getTime();
            return now > accessExpires;
        }
    }

    isRefreshTokenExpired() {
        if (this.token.refresh_expires === undefined) {
            return true;
        } else {
            const now = Date.parse(new Date().toString());
            const refreshExpires = Date.parse(TokenService.parseDateString(this.token.refresh_expires.toString()).toString());
            return now > refreshExpires;
        }
    }

}



