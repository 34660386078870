import {Injectable} from '@angular/core';
import {ConfigService, RestAspect} from '@yukawa/chain-base-angular-client';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {QueryResult} from '@yukawa/chain-base-angular-domain';
import {Order} from '../../base/core/domain';
import {map, mergeMap} from 'rxjs/operators';
import {YukawaProgressBarService} from '../../../shared/services/progress-bar.service';
import {delayAndShowProgress} from '../../../shared/util/rxjs-utils';

@Injectable({
    providedIn: 'root'
})
export class OrderService extends RestAspect {

    constructor(protected http: HttpClient,
                configService: ConfigService,
                protected progressBarService: YukawaProgressBarService) {
        super(http, configService, configService.formatUrl('ordersUrl'));
    }

    queryOrders(filter: any): Observable<QueryResult<Order>> {
        return this.query<Order>(this.formatServiceUrl('/query'), filter);
    }

    loadOrder(orderId: string): Observable<Order> {
        return this.load<Order>(`${this.formatServiceUrl('')}?key=${orderId}`);
    }

    merge(order: Order): Observable<Order> {
        return this.http.post(this.formatServiceUrl('/merge'), order);
    }

    cancel(order: Order): Observable<Order> {
        return this.http.post(`${this.formatServiceUrl('/cancel')}?cancellationReason=${order.cancellationReason}&orderId=${order.orderId}`,null);
    }

    delete(orderId: string): Observable<Order> {
        return this.http.delete(`${this.formatServiceUrl('')}?key=${orderId}`);
    }
    updateShopOrderId(orderId:string,shopOrderId:string){
        return this.http.post(`${this.formatServiceUrl('/updateShopOrderId')}?orderId=${orderId}&shopOrderId=${shopOrderId}`,null);
    }

    addTrackingNumber(orderId:string,trackingNumber:string){
        return this.http.post(`${this.formatServiceUrl('/addTrackingNumber')}?orderId=${orderId}&trackingNumber=${trackingNumber}`,null);
    }

    synchronizeOrder(orderId: string): Observable<any> {
        return of(orderId).pipe(
            mergeMap(orderId => this.validateOrderId(orderId)), // validate
            mergeMap(orderId => this.delete(orderId).pipe(map(() => orderId))), // delete old order
            delayAndShowProgress(this.progressBarService),
            map(orderId => this.splitOrderId(orderId)), // split to shopOrderPrefix and order number
            mergeMap(val => { // sync  order
                return this.http.post(
                    `${this.configService.formatUrl(
                        (val.storefront === 'DE' ?
                            'bigCommerceDeOrderUrl' :
                            (val.storefront === 'US' ? 'bigCommerceUsOrderUrl' : (val.storefront === 'IT' ? 'bigCommerceIntOrderUrl' : 'bigCommerceUkOrderUrl')))
                    )}/synchronizeOrder?orderId=${val.number}`,
                    {});
            }),
            delayAndShowProgress(this.progressBarService) // wait for elastic to sync
        );
    }

    validateOrderId(orderId: string): Observable<string> {
        const validStoreFront: boolean = ['DE', 'US', 'IT', 'UK'].some(pref => pref === orderId.substring(0, 2));
        if (!validStoreFront || isNaN(Number(orderId.substring(2, orderId.length)))) {
            return throwError({error: {text: 'The order id could not be mapped to a store front.'}});
        }

        return of(orderId);
    }

    splitOrderId(orderId: string): { storefront: string, number: number } {
        return {
            storefront: orderId.substring(0, 2), number: parseInt(orderId.substring(2, orderId.length))
        };
    }
}
