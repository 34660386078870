import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {YukawaProgressBarService} from "../services/progress-bar.service";


@Injectable()
export class ProgressBarInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(private progressService: YukawaProgressBarService) {
    }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        if (this.requests.length > 0) {
            this.progressService.show()
        } else this.progressService.hide()
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(req);
        console.debug(req.urlWithParams)
        this.progressService.show();
        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        console.error(`ProgressBarInterceptor => intercept : ${err}`);
                        this.removeRequest(req);
                        observer.error(err);
                    })
        });
    }
}
