import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {OrderDetailService} from '../order-detail.service';
import {Order, OrderStatus, OrderStatusChange} from '../../../base/core/domain';
import {OrderService} from '../../rest/order.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable, of, zip} from 'rxjs';
import {orderStatus} from '../../core/domain';
import {map, mergeAll, mergeMap, toArray} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {fuseAnimations} from '../../../../../@fuse/animations';

@Component({
    selector: 'sturm-order-status-section',
    templateUrl: './status-section.component.html',
    styleUrls: ['./status-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations
})
export class StatusSectionComponent implements OnInit {

    @Input()
    orderForm: FormGroup;

    order: Order;
    orderStatuses: Observable<{ id: string, name: string } []>;
    parsedStatusChanges: any;
    orderStatusEnum = OrderStatus;

    constructor(public _orderDetailService: OrderDetailService,
                private _orderService: OrderService,
                private _matSnackBar: MatSnackBar,
                private _translate: TranslateService) {
    }

    ngOnInit(): void {
        this.loadOrderStatuusTranslations();
        this._orderDetailService.order$.subscribe(o => {
            this.order = o;
            this.initParsedStatusChangeArray(o);
        });
    }


    isExpandableStatusChange(statusChange: OrderStatusChange): boolean {
        return statusChange.orderStatus === OrderStatus.SENT_TO_PDR ||
            statusChange.orderStatus === OrderStatus.SENT_TO_YUSEN ||
            statusChange.orderStatus === OrderStatus.SHIPPING_CONFIRMED ||
            statusChange.orderStatus === OrderStatus.COMMISSIONED;
    }

    updateOrderStatus(): void {
        if (!this.order) {
            throw new Error('Please create the order first.');
        }

        const newState: string = this.orderForm.get('orderStatus').value;

        this._orderService
            .merge({orderId: this.order.orderId, orderStatus: newState as OrderStatus})
            .subscribe(val => {
                this._matSnackBar.open('Status updated successfully', null,
                    {panelClass: 'yuk-snackbar-success', duration: 1300});
                console.debug('Updated order status to ' + newState);
            });
    }


    private initParsedStatusChangeArray(order: Order): void {
        this.parsedStatusChanges = order.orderStatusChanges.map(statusChange => {
            if (statusChange.orderStatus === OrderStatus.SENT_TO_YUSEN ||
                statusChange.orderStatus === OrderStatus.COMMISSIONED ||
                statusChange.orderStatus === OrderStatus.SHIPPING_CONFIRMED ||
                statusChange.orderStatus === OrderStatus.SENT_TO_PDR) {

                (statusChange as Object)['parsedNotes'] = this.parseCsvRowsFromNotesString(statusChange);
                (statusChange as Object)['expanded'] = false;
            }
            return statusChange;
        });
    }

    private loadOrderStatuusTranslations(): void {
        this.orderStatuses = of(orderStatus)
            .pipe(mergeAll(), mergeMap(val => {
                return zip(this._translate.get('ORDER.STATUS.' + val), of(val));
            }))
            .pipe(map(val => {
                return {
                    name: val[0],
                    id: val[1]
                };
            }), toArray());
    }

    parseCsvRowsFromNotesString(statusChange: OrderStatusChange): Array<string []> {
        const notes: string = statusChange.notes;
        if (notes) {
            const rows: string [] = notes.split('\n');
            const result: Array<string []> = new Array<string []>();

            rows.forEach(row => {
                result.push(row.split(';').map(item => item.trim()));
            });
            return result;
        }
        return [];
    }


}
