import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'orders',
                title    : 'Orders',
                translate: 'NAV.ORDERS.TITLE',
                type     : 'item',
                icon     : 'receipt',
                url      : 'orders',
             /*   badge    : {
                    title    : '25',
                    translate: 'NAV.ORDERS.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }*/
            },
            {
                id       : 'logistics_report',
                title    : 'Logistics Report',
                translate: 'NAV.LOGISTICS_REPORT.TITLE',
                type     : 'item',
                icon     : 'view_list',
                url      : 'logistics',
                scope    : ["ROLE_ADMIN", "ROLE_REPORT_READ"]
            },
            {
                id       : 'settings',
                title    : 'Settings',
                translate: 'NAV.SETTINGS.TITLE',
                type     : 'item',
                icon     : 'settings',
                url      : 'settings',
                scope    : ["ROLE_ADMIN", "ROLE_SETTING_EDIT"]
                /*   badge    : {
                       title    : '25',
                       translate: 'NAV.ORDERS.BADGE',
                       bg       : '#F44336',
                       fg       : '#FFFFFF'
                   }*/
            }
        ]
    }
];
