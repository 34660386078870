import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {AppMaterialModule} from "../../modules/material/app.material.module";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
    declarations: [ConfirmDialogComponent],
    imports: [
        CommonModule,
        AppMaterialModule,
        TranslateModule
    ],
    exports: [ConfirmDialogComponent]
})
export class DialogModule {
}
