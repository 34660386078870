import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {AuthService} from "../service/auth.service";
import {RoleService} from "../service/role.service";
import {AuthguardService} from "../guard/authguard.service";

@Directive({selector: '[permissionIfAll]'})
export class PermissionIfAllDirective implements OnInit {

    onAuthenticationChanged: BehaviorSubject<any>;

    constructor(
        private templateRef: TemplateRef<any>,
        private authService: AuthService,
        private viewContainer: ViewContainerRef,
        private _roleService: RoleService,
        private _authGuard: AuthguardService
    ) {
        this.onAuthenticationChanged = new BehaviorSubject([]);
    }

    permittedRoles: string[];
    isAllowed = false;

    @Input() set permissionIfAll(permittedRoles: string[]) {
        this.permittedRoles = permittedRoles;
    }

    ngOnInit() {
        if (this.permittedRoles === undefined || this.permittedRoles.length === 0) {
            if (this._authGuard.curentComponentStatus.get("add")) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        } else {
            if (this._authGuard.curentComponentStatus.get("add") || this.authService.hasAllRoles(this.permittedRoles)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        }

    }

}
