import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth/auth.interceptor';
import {ProgressBarInterceptor} from "../interceptors/progress.interceptor";


export const interceptorProviders =
    [
        // { provide: HTTP_INTERCEPTORS, useClass: crossOriginInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: ProgressBarInterceptor, multi: true
        },
    ];