import {Change, Changed, Created, Person} from '@yukawa/chain-base-angular-domain';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.Condition <br/>
*/
export interface Condition {

/**
*	@example EUR
*/
currency?: string;

/**
*	@example description
*/
desc?: string;
mfMainPositionId?: number;

/**
*	@example GFDE
*/
referenceCode?: string;

/**
*	values: MainPrice,NetPrice,VAT,Shipping,ShippingNet,PercentageDiscount,AmountDiscount,CouponDiscount,GiftCertificateDiscount,PaymentMethod<br/>
*	@example MainPrice
*/
type?: string;

/**
*	@example 123
*/
value?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.ErrorStatus <br/>
*/
export interface ErrorStatus {
errorDescription?: string;
lastOccurence?: string;
retryCounter?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.LineItem <br/>
*/
export interface LineItem {
bcSku?: string;
bigCommerceOrderProductId?: number;
bomParent?: boolean;
bomReferenceId?: string;
buyersItemNumber?: string;
buyersItemPosition?: string;
components?: string[];
conditions?: Condition[];
gtin?: string;
itemReferencePositionId?: string;
materialId?: string;
mfMainPositionId?: number;
mfUnit?: string;
name?: string;
netPrice?: number;
netsuiteMaterialId?: string;
options?: Option[];
price?: number;
quantity?: number;
rhiemReferencePositionId?: string;
voucherNumber?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.LineItemChange <br/>
*/
export interface LineItemChange {
deliveryDateNew?: string;
deliveryDateOld?: string;
lineItemPositionId?: number;
materialId?: string;
name?: string;
priceNew?: number;
priceOld?: number;
quantityNew?: number;
quantityOld?: number;
state?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.Option <br/>
*/
export interface Option {
displayName?: string;
id?: number;
name?: string;
value?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.Order <br/>
*/
export interface Order extends  Changed, Created{
b2b?: boolean;
b2bFab?: boolean;
b2bWebShop?: boolean;
cancellationReason?: string;
change?: Change;
conditions?: Condition[];
confirmationDate?: string;
couponCodes?: string[];
created?: Change;
customerId?: string;
deliveries?: any[];
deliveryDate?: string;
errorStatus?: ErrorStatus;
excludeFromIntegration?: boolean;
externalOrderId?: string;
externalOrderNumber?: string;
externalReferencedOrderId?: string;
invoiceNumber?: string;
items?: LineItem[];
lastExportDate?: Date;
logisticProcessingTime?: number;
mfOrderChange?: string;
mfOrderConfirmationId?: string;
mfOrderNumber?: string;
netsuiteCreatedDate?: Date;
netsuiteCustomerId?: string;
netsuiteCustomerPONumber?: string;
netsuiteId?: string;
netsuiteLastModifiedCustomDate?: Date;
netsuiteLastModifiedDate?: Date;
netsuiteStatus?: string;
notes?: string;
orderConfirmations?: OrderConfirmation[];
orderDate?: string;
orderId?: string;
orderStatus?: OrderStatus;
orderStatusChanges?: OrderStatusChange[];
originalShopOrderId?: string;
parties?: OrderParty[];
preOrder?: boolean;
processingHandler?: ProcessingHandler;
procurosTransactionId?: string;
rhiemOrderKey?: string;
rhiemOrderNo?: string;
sapOrderNumber?: string;
senderSystem?: string;
shipHeroOrderId?: string;

/**
*	@deprecated
*/
shipmentInfo?: any;
shopOrderId?: string;
skipInvoice?: boolean;
squareLocationId?: string;
squareLocationName?: string;

/**
*	values: CREATED, REJECTED, CANCELLED, SENT, COMMISSIONED, SHIPPED, COMPLETED<br/>
*/
state?: string;
supplierId?: string;
syncDate?: Date;
tags?: string[];
trackingNumber?: string;

/**
*	values: PURCHASE, SALES<br/>
*/
type?: string;
vatNumber?: string;
warnings?: Warning[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.OrderChange <br/>
*/
export interface OrderChange {
changeDate?: string;
externalOrderId?: string;
lineItemChanges?: LineItemChange[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.OrderConfirmation <br/>
*/
export interface OrderConfirmation {
deliveryDocumentNumber?: string;
deliveryNoteDate?: string;
deliveryNoteTime?: string;
materialDescription?: string;
materialId?: string;
quantity?: number;
status?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.OrderParty <br/>
*/
export interface OrderParty {
gln?: string;
partyId?: string;
person?: Person;

/**
*	values: CUSTOMER, BILL_TO, SHIP_TO<br/>
*/
role?: string;
}

export enum OrderStatus {
READ_FROM_BIG_COMMERCE = "READ_FROM_BIG_COMMERCE",
ENHANCED = "ENHANCED",
SENT_TO_SAP = "SENT_TO_SAP",
DELIVERY_INFO_ADDED = "DELIVERY_INFO_ADDED",
PARTIAL_DELIVERY_INFO_ADDED = "PARTIAL_DELIVERY_INFO_ADDED",
SHIPMENT_CREATED_IN_BIGCOMMERCE = "SHIPMENT_CREATED_IN_BIGCOMMERCE",
PARTIAL_SHIPMENT_CREATED_IN_BIGCOMMERCE = "PARTIAL_SHIPMENT_CREATED_IN_BIGCOMMERCE",
TRACKING_ADDED = "TRACKING_ADDED",
CANCELLED_IN_SAP = "CANCELLED_IN_SAP",
CANCELLED = "CANCELLED",
ARCHIVED = "ARCHIVED",
CUSTOMER_INTEGRATED = "CUSTOMER_INTEGRATED",
SENT_TO_MYFACTORY = "SENT_TO_MYFACTORY",
READY_FOR_RHIEM = "READY_FOR_RHIEM",
SENT_TO_RHIEM = "SENT_TO_RHIEM",
SENT_TO_SHIPHERO = "SENT_TO_SHIPHERO",
SENT_TO_YUSEN = "SENT_TO_YUSEN",
SENT_TO_PDR = "SENT_TO_PDR",
IMPORTED_VIA_EDI = "IMPORTED_VIA_EDI",
IMPORTED_FROM_PROCUROS = "IMPORTED_FROM_PROCUROS",
PROCUROS_VALIDATION_FAILED = "PROCUROS_VALIDATION_FAILED",
CUSTOMER_UPDATED = "CUSTOMER_UPDATED",
PRICES_VALIDATED = "PRICES_VALIDATED",
PRICE_VALIDATION_FAILED = "PRICE_VALIDATION_FAILED",
SHIP_TO_UPDATED = "SHIP_TO_UPDATED",
DELIVERY_INFO_CONFIRMED = "DELIVERY_INFO_CONFIRMED",
DELIVERY_NOTE_CREATED = "DELIVERY_NOTE_CREATED",
READY_FOR_TRACKING_ADDED = "READY_FOR_TRACKING_ADDED",
ORDER_COMPLETED = "ORDER_COMPLETED",
ADVISE_SENT = "ADVISE_SENT",
EDIFACT_INVOICE_SENT = "EDIFACT_INVOICE_SENT",
IMPORTED_FROM_SHOWCASE = "IMPORTED_FROM_SHOWCASE",
IMPORTED_FROM_GELATO = "IMPORTED_FROM_GELATO",
IMPORTED_FROM_MYFACTORY = "IMPORTED_FROM_MYFACTORY",
PO_SENT = "PO_SENT",
READ_FROM_WEBSHOP = "READ_FROM_WEBSHOP",
COMMISSIONED = "COMMISSIONED",
COMMISSION_CONFIRMED = "COMMISSION_CONFIRMED",
SHIPPED = "SHIPPED",
SHIPMENT_INFO_ADDED = "SHIPMENT_INFO_ADDED",
SHIPPING_CONFIRMED = "SHIPPING_CONFIRMED",
NUORDER_APPROVED = "NUORDER_APPROVED",
READY_FOR_NETSUITE = "READY_FOR_NETSUITE",
SENT_TO_NETSUITE = "SENT_TO_NETSUITE",
NETSUITE_CONFIRMED = "NETSUITE_CONFIRMED",
NETSUITE_MODIFIED = "NETSUITE_MODIFIED",
IMPORTED_FROM_NETSUITE = "IMPORTED_FROM_NETSUITE",
SENT_TO_NUORDER = "SENT_TO_NUORDER"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.OrderStatusChange <br/>
*/
export interface OrderStatusChange extends  Change{
orderStatus?: OrderStatus;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.ProcessingHandler <br/>
*/
export interface ProcessingHandler {
inProcessingSince?: string;
isInProcessing?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.sturm.base.core.domain.order.Warning <br/>
*/
export interface Warning extends  Created{
created?: Change;
warningDescription?: string;
}

