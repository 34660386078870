import { environment } from 'environments/environment';
import { OrderStatus } from '../../base/core/domain';


export const orderStatus: string[] = [];

for (const n in OrderStatus) {
    if (typeof OrderStatus[n] === 'string') {
        if (!environment.production) {
            console.log(OrderStatus[n]);
        }
        orderStatus.push(OrderStatus[n]);
    }
}

export enum ConditionType {
    TYPE_MAIN_PRICE          = "MainPrice",
    TYPE_NET_PRICE           = "NetPrice",
    TYPE_VAT                 = "VAT",
    TYPE_SHIPPING            = "Shipping",
    TYPE_PERCENTAGE_DISCOUNT = "PercentageDiscount",
    TYPE_AMOUNT_DISCOUNT     = "AmountDiscount",
    TYPE_COUPON_DISCOUNT     = "CouponDiscount",
    TYPE_PAYMENT_METHOD      = "PaymentMethod"
}

export enum STATUS_SEQUENCE_US {
    READ_FROM_BIG_COMMERCE = 1,
    CUSTOMER_INTEGRATED    = 2,
    ENHANCED               = 3,
    SENT_TO_MYFACTORY      = 4,
    SENT_TO_YUSEN          = 5,
    SENT_TO_PDR            = 6,
    COMMISSIONED           = 7,
    COMMISSION_CONFIRMED   = 8,
    SHIPPED                = 9,
    SHIPPING_CONFIRMED     = 10,
    TRACKING_ADDED         = 11,
    CANCELLED              = 12

}

export enum STATUS_SEQUENCE_DE {
    READ_FROM_BIG_COMMERCE = 1,
    CUSTOMER_INTEGRATED    = 2,
    ENHANCED               = 3,
    SENT_TO_MYFACTORY      = 4,
    SENT_TO_YUSEN          = 5,
    SENT_TO_PDR            = 6,
    COMMISSIONED           = 7,
    COMMISSION_CONFIRMED   = 8,
    SHIPPED                = 9,
    SHIPPING_CONFIRMED     = 10,
    TRACKING_ADDED         = 11,
    CANCELLED              = 12
}