import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {BehaviorSubject, Observable} from "rxjs";
import {Order} from "../../base/core/domain";
import {QueryTableSource} from "@yukawa/chain-base-angular-store";
import {OrderFilter} from "../../base/core/filter";
import {OrderService} from "../rest/order.service";

export class OrderTableDatasource extends QueryTableSource<OrderFilter, Order> {

    public constructor(protected service: OrderService, protected paginator?: MatPaginator,
                       protected sort?: MatSort,
                       protected tableFilter?: any) {
        super(paginator, sort, tableFilter);
        this.filterSubject = new BehaviorSubject<OrderFilter>(tableFilter);
    }


    protected queryTable(): Observable<any> {
        let filter = this.findFilter();
        this.applyFilter(filter);
        return this.service.queryOrders(filter);
    }

}
