import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatChip} from "@angular/material/chips";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-chip-select',
    templateUrl: './chip-select.component.html',
    styleUrls: ['./chip-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ChipSelectComponent,
            multi: true,
        },
    ],
})
export class ChipSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor {

    @Input() name: string;

    @ViewChild(MatChip) chip: MatChip;

    value: boolean = null;

    onChange: (value: boolean) => void;

    constructor() {
    }

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(value: boolean): void {
        if (this.chip && value) {
            this.selectChip();
        }

        this.value = value;
    }

    selectChip() {
        this.chip.selected = true;
    }

    propagateChange(value: boolean) {
        if (this.onChange) {
            this.onChange(value);
        }
    }

    ngAfterViewInit() {
        this.chip.selectionChange
            .pipe(
                map((event) => event.source))
            .subscribe((chip) => {
                if (chip.selected) {
                    this.value = true;
                } else {
                    this.value = null;
                }

                this.propagateChange(this.value);
            });
    }

}
