import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChipSelectComponent} from "./chip-select.component";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
    declarations: [
        ChipSelectComponent
    ],
    imports: [
        CommonModule,
        MatChipsModule,
        MatIconModule
    ],
    exports: [ChipSelectComponent]
})
export class ChipSelectModule {
}
