import {Person} from '@yukawa/chain-base-angular-domain';
import {Condition, Order, OrderParty} from '../base/core/domain';
import {ConditionType} from './core/domain';

export class OrderUtils {

    public static getOrderPartyPerson(order: Order, role: string): Person {
        let party: OrderParty;
        if (order.parties) {
            party = order.parties.find(val => val.role === role);
        }
        return party ? party.person : null;
    }

    public static getCondition(order: Order, type: ConditionType): Condition {
        return order.conditions ? order.conditions.find(val => val.type === type) : null;
    }

    public static getFullName(person: Person): string {
        return person.firstName ? person.firstName + ' ' + person.lastName : person.companyName;
    }

}
