import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from "../service/auth.service";

@Directive({selector: '[permissionAny]'})
export class PermissionAnyDirective implements OnInit {
    constructor(
        private templateRef: TemplateRef<any>,
        private authService: AuthService,
        private viewContainer: ViewContainerRef
    ) {
    }

    roles: string[];

    @Input() set permissionAny(permittedRoles: string[]) {
        this.roles = permittedRoles;
    }

    ngOnInit() {
        if (this.authService.hasAnyRole(this.roles)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

}
