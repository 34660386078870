import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {LineItem, Order, OrderStatus} from "../../../base/core/domain";
import {OrderService} from "../../rest/order.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {OrderDetailService} from "../order-detail.service";
import {delayAndShowProgress} from "../../../../shared/util/rxjs-utils";
import {YukawaProgressBarService} from "../../../../shared/services/progress-bar.service";

@Component({
    selector: 'sturm-order-details-items-list',
    templateUrl: './items-list.component.html',
    styleUrls: ['./items-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsListComponent implements OnInit {
    @Input()
    orderForm: FormGroup;
    @Output() itemsSave: EventEmitter<any> = new EventEmitter();

    skuEditable: boolean;
    itemsCtrl: FormArray;
    order: Order;

    constructor(private _orderService: OrderService,
                private _orderDetailService: OrderDetailService,
                private _matSnackBar: MatSnackBar,
                private _progressBarService: YukawaProgressBarService) {
    }

    ngOnInit(): void {
        this.itemsCtrl = this.orderForm.get('items') as FormArray;
        this._orderDetailService.order$.subscribe(o => {
            this.order = o;
            this.skuEditable = this.isSkuEditable();
        });
    }

    isSkuEditable(): boolean {
        return this.order.orderStatus === OrderStatus.READ_FROM_BIG_COMMERCE ||
            this.order.orderStatus === OrderStatus.ENHANCED ||
            this.order.orderStatus === OrderStatus.CUSTOMER_INTEGRATED ||
            this.order.orderStatus === OrderStatus.IMPORTED_VIA_EDI ||
            this.order.orderStatus === OrderStatus.IMPORTED_FROM_PROCUROS ||
            this.order.orderStatus === OrderStatus.PRICES_VALIDATED ||
            this.order.orderStatus === OrderStatus.PRICE_VALIDATION_FAILED ||
            this.order.orderStatus === OrderStatus.CUSTOMER_UPDATED ||
            this.order.orderStatus === OrderStatus.SHIP_TO_UPDATED;
    }

    saveItems(name: string): void {
        const lineItems: LineItem [] = this.itemsCtrl.getRawValue();
        console.debug('Saving line items ', lineItems);
        this._orderService.merge({
            orderId: this.order.orderId,
            items: lineItems
        }).pipe(delayAndShowProgress(this._progressBarService))
            .subscribe(o => {
                this._matSnackBar.open('Successfully updated the LineItem ' + name, null,
                    {
                        panelClass: 'yuk-snackbar-success',
                        duration: 5000,
                    });
                this.itemsSave.emit();
            });
    }
}
