import {ReplaySubject} from 'rxjs';
import {Person} from '@yukawa/chain-base-angular-domain';
import {Order} from '../../base/core/domain';
import {map, tap} from 'rxjs/operators';
import {OrderUtils} from '../util';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class OrderDetailService {

    private _order$: ReplaySubject<Order>;

    private _shipTo$: ReplaySubject<Person>;
    private _billTo$: ReplaySubject<Person>;
    private _customer$: ReplaySubject<Person>;

    private _csvCols$: ReplaySubject<string []>;
    // wavc Csv
    private _wavcCsvCols$: ReplaySubject<string []>;
    // obdc csv
    private _obdcCsvCols$: ReplaySubject<string []>;
    // csv that is sent to pdr
    private _csvToPdrCols$: ReplaySubject<string []>;

    constructor(private _translate: TranslateService) {
        this.initSubjects();

        this._order$.pipe(tap(order => {
            this.initProps(order);
        })).subscribe();

    }

    private initSubjects(): void {
        this._order$ = new ReplaySubject<Order>(1);
        this._shipTo$ = new ReplaySubject<Person>(1);
        this._billTo$ = new ReplaySubject<Person>(1);
        this._customer$ = new ReplaySubject<Person>(1);
        this._csvCols$ = new ReplaySubject<string []>(1);
        this._wavcCsvCols$ = new ReplaySubject<string []>(1);
        this._obdcCsvCols$ = new ReplaySubject<string []>(1);
        this._csvToPdrCols$ = new ReplaySubject<string []>(1);
    }

    private initProps(order: Order): void {
        this.customer$.next(OrderUtils.getOrderPartyPerson(order, 'CUSTOMER'));
        this.billTo$.next(OrderUtils.getOrderPartyPerson(order, 'BILL_TO'));
        this.shipTo$.next(OrderUtils.getOrderPartyPerson(order, 'SHIP_TO'));

        this._translate.get('YUSEN_CSV.COLS').pipe(map(val => Object.values(val))).subscribe(this.csvCols$);
        this._translate.get('CSV.YUSEN.WAVC.COLS').pipe(map(val => Object.values(val))).subscribe(this.wavcCsvCols$);
        this._translate.get('CSV.YUSEN.OBDC.COLS').pipe(map(val => Object.values(val))).subscribe(this.obdcCsvCols$);
        this._translate.get('CSV.PDR.TO.COLS').pipe(map(val => Object.values(val))).subscribe(this.csvToPdrCols$);
    }


    get order$(): ReplaySubject<Order> {
        return this._order$;
    }

    get shipTo$(): ReplaySubject<Person> {
        return this._shipTo$;
    }

    get billTo$(): ReplaySubject<Person> {
        return this._billTo$;
    }

    get customer$(): ReplaySubject<Person> {
        return this._customer$;
    }


    get csvCols$(): ReplaySubject<string[]> {
        return this._csvCols$;
    }

    get wavcCsvCols$(): ReplaySubject<string[]> {
        return this._wavcCsvCols$;
    }

    get obdcCsvCols$(): ReplaySubject<string[]> {
        return this._obdcCsvCols$;
    }

    get csvToPdrCols$(): ReplaySubject<string[]> {
        return this._csvToPdrCols$;
    }
}
