<div id="orders" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0"
                 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    business
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Orders
                </span>
            </div>
            <!-- / APP TITLE -->


        </div>
        <!-- / HEADER -->


        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="accent">search</mat-icon>
                    <ng-container [formGroup]="filterForm">
                        <input #searchField placeholder="Search for an order..." formControlName="keyword">
                    </ng-container>

                <!--    <mat-icon color="accent" style="cursor: pointer" (click)="resetFilter()">
                        clear
                    </mat-icon>-->

                </div>

            </div>
            <!-- / SEARCH -->
     <!--       <div>
                <button mat-mini-fab>
                    <mat-icon>clear</mat-icon>
                </button>
            </div>
-->
        </div>


        <!-- CONTENT CARD -->
        <div class="content-card">

            <div fxLayout="row" fxLayoutAlign="space-between">
                <div fxLayout="row" fxFlex="0 0 50">
                    <div class="primary-A50-bg p-12 m-12" fxFlex="1 1 100">

                        <div fxLayout="column" fxLayoutGap="10px">

                            <mat-chip-list #list selectable multiple>
                                <ng-container [formGroup]="filterForm">
                                    <app-chip-select formControlName="hasError" name="Error"></app-chip-select>
                                    <app-chip-select formControlName="excludeFromIntegration"
                                                     name="Excluded-orders"></app-chip-select>
                                    <app-chip-select formControlName="preOrder" name="Pre-order"></app-chip-select>
                                    <app-chip-select formControlName="hasWarning" name="Warning"></app-chip-select>
                                </ng-container>
                                <!-- <mat-chip #c="matChip" *ngFor="let option of chipOptions" [value]="option.id"
                                           [ngClass]="{'accent-bg': c.selected, 'white-fg': c.selected}"
                                           (click)="toggleChipSelection(c)">
                                     <div fxLayout="row" fxLayoutAlign="center center">
                                         <mat-icon class="font-size-18" style="line-height: 23px" *ngIf="c.selected">
                                             checkbox
                                         </mat-icon>
                                         <mat-icon class="font-size-18" style="line-height: 23px" *ngIf="!c.selected">
                                             check_box_outline_blank
                                         </mat-icon>
                                         <span>{{option.view}}</span>
                                     </div>

                                 </mat-chip>-->


                            </mat-chip-list>


                            <div fxLayout="row" fxLayoutGap="10px">

                                <!--          <mat-form-field class="ca80" appearance="outline" formControlName="">
                                              <mat-select multiple name="type_list" placeholder="Store front">
                                                  <mat-option> {{"DE"}}</mat-option>
                                                  <mat-option> {{"US"}}</mat-option>
                                                  <mat-option> {{"INT"}}</mat-option>
                                              </mat-select>
                                          </mat-form-field>-->
                                <ng-container [formGroup]="filterForm">

                                    <mat-form-field class="ca80" appearance="outline" fxFlex="0 0 60">
                                        <mat-select multiple placeholder="Order status"
                                                    formControlName="orderStatuses">
                                            <mat-option *ngFor="let status of $orderStatuses | async"
                                                        [value]="status.id">
                                                {{status.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </ng-container>


                            </div>

                        </div>

                    </div>
                </div>
                <mat-paginator #paginator [pageSizeOptions]="[10, 20, 50, 100, 200]">
                </mat-paginator>
            </div>

            <mat-table #table [dataSource]="dataSource" [@animateStagger]="{value:'50'}" class="orders-table"
                       matSort matSortDisableClear matSortActive="orderDate" matSortDirection="desc"
                       fusePerfectScrollbar multiTemplateDataRows>

                <ng-container matColumnDef="orderId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORDER.CDM.ID' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" [ngClass]="{'red-fg': row?.errorStatus?.errorDescription}">

                        <mat-icon [ngClass]="{'orange-fg': row?.warnings}" style="font-size: 20px; margin-right: 5px;margin-bottom:-5px"
                                  *ngIf=" row?.warnings"
                                  [class.expanded]="expandedElement == row">warning
                        </mat-icon>
                        <mat-icon style="font-size: 20px; margin-right: 5px;margin-bottom:-5px"
                                  *ngIf=" row?.errorStatus?.errorDescription"
                                  matTooltip="{{row?.errorStatus?.errorDescription}}"
                                  [class.expanded]="expandedElement == row">error
                        </mat-icon>
                        <p class="text-truncate">{{row.orderId}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="customerId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORDER.CDM.CUSTOMER_ID' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" [ngClass]="{'red-fg': row?.errorStatus?.errorDescription}">
                        <p class="text-truncate">{{row?.customerId}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="orderDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORDER.CDM.DATE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" matTooltip="{{row?.orderDate | date : 'long'}}"
                              [ngClass]="{'red-fg': row?.errorStatus?.errorDescription}">
                        <p class="text-truncate">{{row?.orderDate | date: ('DateFormat.date' | translate)}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORDER.CDM.STATUS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" [ngClass]="{'red-fg': row?.errorStatus?.errorDescription}">
                        <p class="text-truncate text-boxed">{{'ORDER.STATUS.' + row?.orderStatus | translate}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="shopOrderId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORDER.CDM.SHOP_ORDER_ID' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" [ngClass]="{'red-fg': row?.errorStatus?.errorDescription}">
                        <p class="text-truncate">{{row?.shopOrderId}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORDER.CDM.TYPE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" [ngClass]="{'red-fg': row?.errorStatus?.errorDescription}">
                        <p class="text-truncate">{{"ORDER.TYPE." + row?.type | translate}}</p>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="senderSystem">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORDER.CDM.SENDER_SYSTEM' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" [ngClass]="{'red-fg': row?.errorStatus?.errorDescription}">
                        <p class="text-truncate">{{row?.senderSystem}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <mat-cell *matCellDef="let detail">
                        <!-- TODO put some info here -->
                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>


                <mat-row [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}" (click)="loadOrder(row)"
                         [class.expanded]="expandedElement == row"
                         *matRowDef="let row; columns: displayedColumns;" matRipple class="order element-row">
                </mat-row>

                <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
                         [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'"
                         style="overflow: hidden">
                </mat-row>


            </mat-table>


        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

