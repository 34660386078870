import { Injectable } from "@angular/core";
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from "@angular/material/snack-bar";


enum NotificationType {
  success = 'notify_success',
  error = 'notify_error',
  warning = 'notify_warning',
  info = 'notify_info'
}

@Injectable()
export class NotificationService {
  private config: MatSnackBarConfig;
  private snackBarRef: MatSnackBarRef<any>;
  private snackBarAutoHide = "2000"; //milliseconds for notification , 5 secs

  constructor(private sb: MatSnackBar) { }

  openNotificationBar(message, type = "default") {
    this.config = new MatSnackBarConfig();
    this.config.verticalPosition = "top";
    this.config.horizontalPosition = "right";
    this.config.duration = 2000;
    this.config.panelClass = NotificationType[type] == null ? ['notify_default'] : [NotificationType[type]];
    //this.snackBarConfig.horizontalPosition = this.horizontalPosition; only in current version Demo uses very old version . need to upgrade later
    //this.snackBarConfig.verticalPosition = this.verticalPosition; only in current version Demo uses very old version . need to upgrade later
    this.config.duration = parseInt(this.snackBarAutoHide, 0);
    this.sb.open(message, "", this.config);
  }

}