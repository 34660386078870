import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../service/auth.service';


@Injectable({providedIn: 'root'})
export class AuthguardService implements CanActivate {

    curentComponentStatus: Map<string, boolean> = new Map<string, false>();

    constructor(private router: Router, private authService: AuthService) {
    }

    canActivate(activeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.authService.isLoggedIn()) {
            this.authService.logout();
            this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
            return false;
        } else {
            // for roles purpose
            const currentUserRoles: any[] = this.authService.userRoles;

            if (!this.authService.isAdmin() &&
                this.authService.isLoggedIn() &&
                activeSnapshot.data &&
                activeSnapshot.data.expectedRoles &&
                activeSnapshot.data.expectedRoles.length > 0) {

                // can Edit that Module
                if (!activeSnapshot.data.ifAll) {
                    if (this.authService.hasAnyRole(activeSnapshot.data.expectedRoles) && currentUserRoles.length > 0) {
                        this.curentComponentStatus.set("add", true);
                        return true;
                    } else {
                        this.curentComponentStatus.set("add", false);
                    }
                }
                // end => can Edit that Module
                else {

                    if (activeSnapshot.data.ifAll === false && this.authService.hasAnyRole(activeSnapshot.data.expectedRoles) && currentUserRoles.length > 0) {
                        this.curentComponentStatus.set("add", true);
                        return true;
                    } else if (activeSnapshot.data.ifAll === true && this.authService.hasAllRoles(activeSnapshot.data.expectedRoles) && currentUserRoles.length > 0) {
                        this.curentComponentStatus.set("add", true);
                        return true;
                    } else {
                        this.curentComponentStatus.set("add", false);
                    }
                }

                this.curentComponentStatus.set("add", false);
                return false;
            }

            this.curentComponentStatus.set("add", true);
            return true;
        }
    }

    canLoad(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (!this.authService.isLoggedIn()) {
            this.authService.logout();
            this.router.navigate(['/login']);
            return false;
        } else {
            return true;
        }
    }

}
