import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Pager } from '@yukawa/chain-base-angular-domain';
import { merge, Observable, of, Subject, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeAll, mergeMap, toArray } from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations';
import { TableStateService } from '../../../shared/services/table/table-state.service';
import { Order } from '../../base/core/domain';
import { OrderFilter } from '../../base/core/filter';
import { orderStatus } from '../core/domain';
import { OrderService } from '../rest/order.service';
import { OrderTableDatasource } from './order-table.datasource';


const TAG = "sturm-order-table"

@Component({
    selector: 'sturm-order-table',
    templateUrl: './order-table.component.html',
    styleUrls: ['./order-table.component.scss'],
    animations: fuseAnimations.concat([
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', visibility: 'hidden'})),
            state('expanded', style({height: '*', visibility: 'visible'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],)
})
export class OrderTableComponent implements OnInit, OnDestroy {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    @Input() displayedColumns: string [] = ['orderId', 'shopOrderId', 'type', 'senderSystem', 'customerId', 'orderDate', 'state'];

    dataSource: OrderTableDatasource;
    filter: OrderFilter = {};

    isExpansionDetailRow = (i: number, row: Object) => {
        return true;
    }
    expandedElement: any;

    chipOptions: any[] = [
        {view: "Error", id: "hasError"},
        {view: "Excluded-orders", id: "excludeFromIntegration"},
        {view: "Pre-order", id: "preOrder"}
    ];
    $orderStatuses: Observable<any>;

    filterForm: FormGroup;

    private unsubscribe: Subject<any>;

    constructor(private service: OrderService,
                private _router: Router,
                private _translate: TranslateService,
                private _activatedRoute: ActivatedRoute,
                private _tableStatus: TableStateService,
                private _fb: FormBuilder) {
        this.unsubscribe = new Subject<any>();
    }

    ngOnInit(): void {
        this.initFilterForm();
        this.handleKeyword();
        this.handleFilters();
        this.initDataSource();
        this.initOrderStatuus();


  /*      this.filterForm.valueChanges.pipe(startWith(null), pairwise(), debounce(val => {

            /!*         if (val[0] != null) {
                         console.log(_.isEqual(val[0].keyword, val[1].keyword))

                         console.log(val[0].keyword);
                         console.log(val[1].keyword)
                         return !(_.isEqual(val[0].keyword, val[1].keyword)) ? interval(300) : interval(0);
                     }*!/

            return interval(0);

        })).subscribe(val => {
            let filter = val[1];
            if (filter['orderStatuses'] && (filter['orderStatuses']).length == 0) {
                filter['orderStatuses'] = null;
            }

            if (filter['keyword'] == '')
                filter['keyword'] = null;

            filter['keyword'] = filter['keyword'] ? filter['keyword'] + "*" : filter['keyword'];


            this.paginator.pageIndex = 0;
            this.mergeApplyFilter(filter)
        })*/
    }

    private handleFilters() {
        // keyword needs to be handled separately, therefore emrge the others
        merge(
            this.filterForm.get('preOrder').valueChanges.pipe(map(val =>
            {
                return { preOrder: val };
            })),
            this.filterForm.get('excludeFromIntegration').valueChanges.pipe(map(val =>
            {
                return { excludeFromIntegration: val };
            })),
            this.filterForm.get('hasError').valueChanges.pipe(map(val =>
            {
                return { hasError: val };
            })),
            this.filterForm.get('hasWarning').valueChanges.pipe(map(val =>
            {
                return { hasWarning: val };
            })),
            this.filterForm.get('orderStatuses').valueChanges.pipe(map(val =>
            {
                return { orderStatuses: val };
            })),
        ).subscribe(filter =>
        {
            if (filter['orderStatuses'] && (filter['orderStatuses']).length == 0) {
                filter['orderStatuses'] = null;
            }
            this.paginator.pageIndex = 0;
            this.mergeApplyFilter(filter);
        });
    }

    private initOrderStatuus() {
        this.$orderStatuses = of(orderStatus)
            .pipe(mergeAll(), mergeMap(val => {
                return zip(this._translate.get('ORDER.STATUS.' + val), of(val))
            }))
            .pipe(map(val => {
                return {
                    name: val[0],
                    id: val[1]
                }
            }), toArray());
    }

    private handleKeyword() {
        this.filterForm.get('keyword')
            .valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(value => {

                if (value == '')
                    value = null;
                let filter = this.dataSource.filterSubject.getValue();
                filter.keyword = value ? value + "*" : value;
                this.paginator.pageIndex = 0;
                this.applyFilter(filter);
            });
    }

    public loadOrder(row: Order): void {
        this._router.navigate([row.orderId], {relativeTo: this._activatedRoute})
    }


    private initDataSource(): void {

        let tableState: any = this._tableStatus.getFilterValue(TAG);

        let filter = tableState ? tableState.filter : {};

        // init filter form
        this.filterForm.patchValue(filter, {emitEvent: false});

        // init pager
        if (tableState && tableState.pager) {
            let pager: Pager = tableState.pager;
            this.paginator.pageSize = pager.pageSize;
            this.paginator.pageIndex = pager.firstResult / pager.pageSize;
        }

        // init sort
        if (tableState && tableState.sort) {
            let sort: Sort = tableState.sort;
            this.sort.direction = sort.direction.toLowerCase() as SortDirection;
            this.sort.active = sort.active;
        }

        if (filter.keyword) {
            filter.keyword += "*"
        }

        if (filter.keyword === "") {
            filter.keyword = null;
        }

        if (filter.orderStatuses && filter.orderStatuses.length === 0) {
            filter.orderStatuses = null;
        }

        this.filter = filter;

        this.dataSource = new OrderTableDatasource(
            this.service,
            this.paginator,
            this.sort,
            this.filter
        );

    }

    protected applyFilter(filter: OrderFilter): void {
        this.dataSource.filterSubject.next(filter);
    }

    protected mergeApplyFilter(filter: OrderFilter): void {
        this.filter = {...this.dataSource.filterSubject.getValue(), ...filter};
        this.dataSource.filterSubject.next(this.filter);
    }


    initFilterForm() {
        this.filterForm = this._fb.group({
            keyword: [null, null],
            hasError: [null, null],
            hasWarning: [null, null],
            preOrder: [null, null],
            excludeFromIntegration: [null, null],
            orderStatuses: [null, null]
        })
    }


    ngOnDestroy(): void {
        this.unsubscribe.next(null);
        this.unsubscribe.complete();

        // save filter settings
        this._tableStatus.setStatus(TAG, this.filterForm.getRawValue(), this.filter.pager, {
            active: this.filter.orderBy,
            direction: this.filter.orderDir as SortDirection
        });
    }


}
