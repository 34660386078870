import {Component, isDevMode, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FuseConfigService} from "../../../@fuse/services/config.service";
import {FuseTranslationLoaderService} from "../../../@fuse/services/translation-loader.service";
import {ActivatedRoute, Router} from "@angular/router";
import {fuseAnimations} from "../../../@fuse/animations";
import {AuthService, TokenService} from "../../shared/modules/security/service/auth.service";
import {NotificationService} from "../../shared/services/notification/notification.service";

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param _fuseTranslationLoaderService
     * @param _router
     * @param _authService
     * @param _tokenService
     * @param _notifiyService
     * @param activatedRoute
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _router: Router,
        private _authService: AuthService,
        private _tokenService: TokenService,
        private _notifiyService: NotificationService,
        private activatedRoute: ActivatedRoute
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            username: [isDevMode() ? 'admin' : '', [Validators.required]],
            password: [isDevMode() ? 'admin' : '', Validators.required]
        });
    }

    login(): void {
        this._authService.loginForm(this.loginForm.value).subscribe(response => {
            this._router.navigate([this.activatedRoute.snapshot.queryParams["returnUrl"] || "/orders"]);
        }, error => {
            this._notifiyService.openNotificationBar("Invalid Username or Password", "error");
            console.error(`LoginComponent Login  ${error} `);
        });
    }

}
