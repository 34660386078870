import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { fromEvent, merge, Observable, Observer, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  // public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  // public httpOptions = {
  //   headers: this.headers
  //     .append('Access-Control-Allow-Origin', '*')
  // };
  isConnect: boolean;
  baseUrl: string;
  constructor(private http: HttpClient, configService: ConfigService,
    // private notification: NotificationService
    ) {
    this.baseUrl = configService.getBaseUrl();
    this.createOnline$().subscribe((isOnline: boolean) =>
    {
        console.log('Internet Connection :', isOnline);
        //  if (!isOnline) notification.openNotificationBar("No Internet Connection !", "info")
        this.isConnect = isOnline;
    });
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(`HttpService  : ${error}`); // log to console instead
      // this.notification.openNotificationBar("Error Occured !", "error")
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  Get(url: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + url) //environment.baseUrl+url,this.httpOptions //for sample json use ./assets/sample.json
      .pipe(
        tap((temp: any) => { }),
        catchError(this.handleError([]))
      );
  }

  Post(url: string, object: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + url, object).pipe(
      tap((res: any) => res),
      catchError(this.handleError([]))
    );
  }

  Delete(url: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl + url).pipe(
      tap((res: any) => res),
      catchError(this.handleError([]))
    );
  }
  PUT(url: string, object: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + url, object).pipe(
      tap((res: any) => res),
      catchError(this.handleError([]))
    );
  }
  DownloadPDF(url?: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(this.baseUrl + url, { headers: headers, responseType: "blob" });
  }

  //connectionCheck
  private createOnline$() {
      return merge(
          fromEvent(window, 'offline').pipe(map(() => false)),
          fromEvent(window, 'online').pipe(map(() => true)),
          new Observable((sub: Observer<boolean>) =>
          {
              sub.next(navigator.onLine);
              sub.complete();
          }),
      );
  }

  isOnline(): boolean {
    return this.isConnect;
  }
}
