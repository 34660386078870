import {MainRealm} from "@yukawa/chain-main-angular-core";
import {BaseRealm} from "@yukawa/chain-base-angular-domain";

export class SturmRealm extends MainRealm {

    static readonly ROLE_ORDER_CANCEL = "ROLE_ORDER_CANCEL";
    static readonly ROLE_ORDER_READ = "ROLE_ORDER_READ";
    static readonly ROLE_ORDER_EDIT = "ROLE_ORDER_EDIT";
    static readonly ROLE_REPORT_READ = "ROLE_REPORT_READ";

    public static readonly ADMINS = [BaseRealm.ROLE_ADMIN];
    public static readonly EDITORS = [...SturmRealm.ADMINS];
    public static readonly READERS = [...SturmRealm.EDITORS];

    public static readonly ORDER_READERS = [...SturmRealm.READERS, SturmRealm.ROLE_ORDER_READ];
    public static readonly ORDER_EDITORS = [...SturmRealm.EDITORS, SturmRealm.ROLE_ORDER_EDIT];
    public static readonly ORDER_CANCELLERS = [SturmRealm.ADMINS, SturmRealm.ROLE_ORDER_CANCEL];
    public static readonly REPORT_READERS = [SturmRealm.READERS, SturmRealm.ROLE_REPORT_READ];

}
