<div class="products tab-content p-24" fusePerfectScrollbar>
    <div fusePerfectScrollbar>
        <table class="simple" fxFlex="0 0 50" fxFlex.lt-lg="0 0 100" fxFlex.lt-sm="0 0 250">
            <thead>
            <tr>
                <th>Name</th>
                <th>Id.</th>
                <th  *ngIf="order?.b2b">Gtin</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <ng-container [formGroup]="orderForm">
                <ng-container formArrayName="items">
                    <tr *ngFor="let ctrl of itemsCtrl.controls; let i=index">
                        <ng-container [formGroupName]="i">
                            <td style="white-space: pre-wrap !important;">
                                <span>{{ctrl.value?.name}}</span></td>
                            <td *ngIf="!skuEditable"><span
                                class="text-truncate">{{ ctrl.value?.materialId}}</span></td>
                            <td *ngIf="skuEditable">
                                <mat-form-field>
                                    <input formControlName="materialId" matInput/>
                                </mat-form-field>
                            </td>
                            <td *ngIf="order?.b2b">
                                <mat-form-field>
                                    <input formControlName="gtin" matInput/>
                                </mat-form-field>
                            </td>
                            <td><span class="text-truncate">{{ctrl.value?.quantity}}</span></td>
                            <td><span class="text-truncate">{{ctrl.value?.price}}</span></td>
                            <td>
                                <button
                                    (click)="saveItems(ctrl.value?.name)"
                                    [disabled]="ctrl.pristine || ctrl.get('materialId').invalid"
                                    mat-mini-fab>
                                    <mat-icon>save</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>

            </ng-container>

            </tbody>
        </table>
    </div>
</div>