import {delay as Delay, mergeMap, tap} from 'rxjs/operators';
import {of, OperatorFunction} from 'rxjs';

export function delayAndShowProgress<T>(progressBarService): OperatorFunction<T, T> {
    return mergeMap((value: T) => {
        return of(value).pipe(
            tap(() => progressBarService.show()),
            Delay(2000),
            tap(() => progressBarService.hide())
        );
    });
}