<div class="customer-section">
    <div class="customer customer-table mb-32" [ngClass]="{'editMode': customerEditMode}">
        <table class="simple">
            <thead>
            <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Company</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngIf="customerPartyForm" [formGroup]="customerPartyForm">
                <tr>
                    <td>
                    <span *ngIf="!customerEditMode"
                          class="company text-truncate">{{(order$ | async)?.customerId}}
                                                </span>
                        <ng-container [formGroup]="orderForm" *ngIf="customerEditMode">
                            <mat-form-field>
                                <input matInput formControlName="customerId">
                            </mat-form-field>
                        </ng-container>
                    </td>

                    <ng-container formGroupName="person">
                        <td>
                            <div fxLayout="row" fxLayoutAlign="start center"
                                 *ngIf="!customerEditMode">
                            <span class="name text-truncate">
                                {{(customer$ | async)?.firstName + ' ' + (customer$ | async)?.lastName }}
                            </span>
                            </div>
                            <div fxLayout="row" fxLayoutGap="5px">
                                <ng-container *ngIf="customerEditMode">
                                    <mat-form-field>
                                        <input matInput formControlName="firstName">
                                    </mat-form-field>
                                    <mat-form-field>
                                        <input matInput formControlName="lastName">
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </td>
                        <td>
                        <span *ngIf="!customerEditMode"
                              class="email text-truncate">{{(customer$ | async)?.email}}
                        </span>

                            <mat-form-field *ngIf="customerEditMode">
                                <input matInput formControlName="email">
                            </mat-form-field>
                        </td>
                        <td>
                        <span *ngIf="!customerEditMode"
                              class="phone text-truncate">{{(customer$ | async)?.mobile}}</span>
                            <mat-form-field *ngIf="customerEditMode">
                                <input matInput formControlName="mobile">
                            </mat-form-field>
                        </td>
                        <td>
                        <span *ngIf="!customerEditMode"
                              class="company text-truncate">{{(customer$ | async)?.companyName}}</span>
                            <mat-form-field *ngIf="customerEditMode">
                                <input matInput formControlName="companyName">
                            </mat-form-field>
                        </td>
                        <td>
                            <ng-container *permissionAny="ORDER_EDITORS">
                                <mat-icon
                                    [@animate]="{value:'*',params:{delay:'100ms',x:'5px'}}"
                                    color="accent" *ngIf="!customerEditMode"
                                    style="cursor: pointer" (click)="customerEditMode = true">
                                    edit
                                </mat-icon>
                            </ng-container>

                            <div *ngIf="customerEditMode" fxLayout="row" fxLayoutGap="5px"
                                 [@animate]="{value:'*',params:{delay:'100ms',x:'-5px'}}">
                                <button mat-mini-fab [disabled]="orderForm.pristine"
                                        (click)="customerEditMode = true; saveCustomer()">
                                    <mat-icon style="cursor: pointer">
                                        save
                                    </mat-icon>
                                </button>

                                <button mat-mini-fab (click)="customerEditMode = false">
                                    <mat-icon style="cursor: pointer">
                                        cancel
                                    </mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>

    <mat-accordion class="addresses">

        <mat-expansion-panel>

            <mat-expansion-panel-header>
                <mat-panel-title>Shipping Address</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="shipping-address-table simple-table-container" fxFlex
                 fxLayout="column">

                <table class="simple" fxFlex="0 0 50" fxFlex.lt-lg="0 0 100">
                    <thead>
                    <tr>
                        <th>{{"ADDRESS.STREET" | translate}} </th>
                        <th>{{"ADDRESS.HOUSE_NUMBER" | translate}}</th>
                        <th>{{"ADDRESS.POST_CODE" | translate}}</th>
                        <th>{{"ADDRESS.CITY" | translate}}</th>
                        <th>{{"ADDRESS.COUNTRY" | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><span
                            fxFlex="0 0 auto">{{(shipTo$ | async)?.addresses[0]?.street | placeholder: '-'}}</span>
                        </td>
                        <td>
                            <span>{{(shipTo$ | async)?.addresses[0].houseNumber | placeholder: '-'}}</span>
                        </td>
                        <td><span
                            class="text-truncate">{{(shipTo$ | async)?.addresses[0].zipCode | placeholder: '-'}}</span>
                        </td>
                        <td><span
                            class="text-truncate">{{(shipTo$ | async)?.addresses[0].city | placeholder: '-'}}</span>
                        </td>
                        <td><span
                            class="text-truncate">{{(shipTo$ | async)?.addresses[0].countryCode | placeholder: '-'}}</span>
                        </td>

                    </tr>
                    </tbody>
                </table>
            </div>

        </mat-expansion-panel>

        <mat-expansion-panel>

            <mat-expansion-panel-header>
                <mat-panel-title>Invoice Address</mat-panel-title>
            </mat-expansion-panel-header>

            <div class="invoice-address-table" fxFlex fxLayout="column">
                <table class="simple" fxFlex="0 0 50" fxFlex.lt-lg="0 0 100">
                    <thead>
                    <tr>
                        <th>{{"ADDRESS.STREET" | translate}} </th>
                        <th>{{"ADDRESS.HOUSE_NUMBER" | translate}}</th>
                        <th>{{"ADDRESS.POST_CODE" | translate}}</th>
                        <th>{{"ADDRESS.CITY" | translate}}</th>
                        <th>{{"ADDRESS.COUNTRY" | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><span
                            fxFlex="0 0 auto">{{(billTo$ | async)?.addresses[0]?.street | placeholder: '-'}}</span>
                        </td>
                        <td>
                            <span>{{(billTo$ | async)?.addresses[0].houseNumber | placeholder: '-'}}</span>
                        </td>
                        <td><span
                            class="text-truncate">{{(billTo$ | async)?.addresses[0].zipCode | placeholder: '-'}}</span>
                        </td>
                        <td><span
                            class="text-truncate">{{(billTo$ | async)?.addresses[0]?.city | placeholder: '-'}}</span>
                        </td>
                        <td><span
                            class="text-truncate">{{(billTo$ | async)?.addresses[0].countryCode | placeholder: '-'}}</span>
                        </td>

                    </tr>
                    </tbody>
                </table>
            </div>

        </mat-expansion-panel>

    </mat-accordion>


</div>

