import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {AuthService} from "../service/auth.service";

@Directive({
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {

    formGroup: FormGroup;
    permissions: string[];
    except: string [];

    @Input() set hasPermission(permissions: string[]) {
        this.permissions = permissions;
    }

    @Input()
    set hasPermissionExcept(except: string []) {
        this.except = except;
    }

    constructor(private templateRef: TemplateRef<any>,
                private authService: AuthService,
                private viewContainer: ViewContainerRef) {
    }

    ngOnInit(): void {
        if (this.authService.hasAnyRole(this.permissions) && !this.authService.hasAnyRole(this.except)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

}
