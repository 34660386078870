import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {AppRoutingModule} from "./app-routing.module";
import {SturmOrderModule} from "./sturm/order/sturm-order.module";
import {ConfigService} from "@yukawa/chain-base-angular-client";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {LoginComponent} from './components/login/login.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {AppMaterialModule} from "./shared/modules/material/app.material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {interceptorProviders} from "./shared/services/interceptors";
import {NotificationService} from "./shared/services/notification/notification.service";
import {AuthService} from "./shared/modules/security/service/auth.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UserService} from "./shared/services/user/user.service";
import {YukawaProgressBarModule} from "./shared/components/progress-bar/progress-bar.module";
import {SettingService} from "@yukawa/chain-main-angular-comp";

export function configFactory(_configService: ConfigService) {
    return (): Promise<any> => {
        return new Promise((resolve, reject) => {

            let locations: string [] = ['config.base.json', 'config.json'];

            _configService.initConfigs(locations).then(cfg => {
                console.info("Loaded config", cfg)
                resolve(cfg)
            }).catch(reason => {
                //resolve(false)
                console.warn("Load config failed", reason);
                alert("Page are not working, please try again later.");
                setTimeout(() => {
                    location.reload();
                }, 10000);
            })
        })
    }
}


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        // Sturm modules
        SturmOrderModule,
        MatCheckboxModule,
        AppMaterialModule,
        YukawaProgressBarModule
    ],
    providers: [
        ConfigService, {
            provide: APP_INITIALIZER,
            useFactory: configFactory,
            deps: [ConfigService],
            multi: true
        },
        interceptorProviders,
        NotificationService,
        UserService,
        AuthService,
        SettingService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}


