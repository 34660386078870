import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations';
import { Person } from '@yukawa/chain-base-angular-domain';
import { ConfirmDialogComponent } from 'app/shared/components/dialog-module/confirm-dialog/confirm-dialog.component';
import { AuthService } from 'app/shared/modules/security/service/auth.service';
import { YukawaProgressBarService } from 'app/shared/services/progress-bar.service';
import { delayAndShowProgress } from 'app/shared/util/rxjs-utils';
import { EMPTY, Observable, zip } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Order } from '../../../base/core/domain';
import { SturmRealm } from '../../../base/core/realm';
import { OrderService } from '../../rest/order.service';
import { OrderDetailService } from '../order-detail.service';


@Component({
    selector       : 'sturm-order-customer-section',
    templateUrl    : './customer-section.component.html',
    styleUrls      : ['./customer-section.component.scss'],
    animations     : fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSectionComponent implements OnInit
{

    public ORDER_EDITORS = SturmRealm.ORDER_EDITORS;

    @Input()
    customerPartyForm: FormGroup;
    @Input()
    orderForm: FormGroup;
    @Output() customerSectionSave: EventEmitter<any> = new EventEmitter();

    order$: Observable<Order>;
    customer$: Observable<Person>;
    billTo$: Observable<Person>;
    shipTo$: Observable<Person>;

    public customerEditMode = false;

    constructor(private _dialog: MatDialog,
                private _orderService: OrderService,
                private _matSnackBar: MatSnackBar,
                public dtService: OrderDetailService,
                public authService: AuthService,
                private progressBarService: YukawaProgressBarService) {
    }

    ngOnInit(): void {
        this.customer$ = this.dtService.customer$.asObservable();
        this.shipTo$ = this.dtService.shipTo$.asObservable();
        this.billTo$ = this.dtService.billTo$.asObservable();
        this.order$ = this.dtService.order$.asObservable();
    }

    saveCustomer(): void {
        const afterClosed$ = this._dialog.open(ConfirmDialogComponent, {
            data: {
                'message': 'Do you really want to save the changes?'
            }
        }).afterClosed();

        zip(afterClosed$, this.dtService.order$)
            .pipe(
                mergeMap(val => {
                    return val[0] ? this._orderService.merge({
                        orderId: val[1].orderId,
                        // parties: this.orderForm.get('parties').value TODO GK be careful that formCtrl contains every value/ or merge before !!
                        customerId: this.orderForm.get('customerId').value
                    }).pipe(delayAndShowProgress(this.progressBarService)) : EMPTY;
                }),
                tap(o => {
                    this._matSnackBar.open('Successfully saved customer', null,
                        {
                            panelClass: 'yuk-snackbar-success',
                            duration: 2500,
                            verticalPosition: 'top',
                            horizontalPosition: 'right'
                        });

                }),)
            .subscribe(o => {
                this.customerSectionSave.emit();
            });
    }


}
