<div class="page-layout carded fullwidth inner-scroll" id="order-detail">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="accent headerv2">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="space-between"
                 fxLayout.lt-sm="column" fxLayoutGap.lt-sm="20px" fxFlex="0 0 100%">

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="space-between" style="width:100%">

                    <div>
                        <button [routerLink]="'../'" class="mr-16" mat-icon-button>
                            <mat-icon>arrow_back</mat-icon>
                        </button>

                    </div>

                    <div [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}" fxLayout="column"
                         fxLayoutAlign="start start">
                        <div class="h2" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                            <span>
                                Order: {{order?.orderId}}
                            </span>
                        </div>
                        <div *ngIf="customer$ | async" class="subtitle secondary-text">
                            <span>from </span>
                            <span>{{fullName(customer$ | async)}}</span>
                        </div>
                        <div class="subtitle secondary-text">
                            <span>Created:</span>
                            <span> {{order?.created?.date | date: ('DateFormat.date' | translate)}}</span>
                        </div>
                        <div class="subtitle secondary-text">
                            <span>Changed:</span>
                            <span> {{order?.change?.date | date: ('DateFormat.date' | translate)}}</span>
                        </div>
                    </div>
                </div>

                <div [formGroup]="orderForm" fxLayout="row" fxLayoutGap="20px" fxFlex.lt-sm="0 0 100%">

                    <div fxLayout="column" fxLayoutGap="5px">

                        <mat-checkbox (change)="toggleExcludeOrderFromIntegration($event)" color="primary"
                                      formControlName="excludeFromIntegration">
                            <span>Exclude from integration</span>
                        </mat-checkbox>
                        <mat-checkbox (change)="togglePreorder($event)" color="primary" formControlName="preOrder">
                            <span>Preorder</span>
                        </mat-checkbox>
                        <button *ngIf="order?.errorStatus?.errorDescription" (click)="removeErrors()" mat-raised-button>
                            Reset error status
                        </button>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>

                    <div>
                        <button (click)="deleteOrder()" color="warn" mat-mini-fab>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>

                    <div>
                        <button class="primary-A400" mat-button mat-mini-fab (click)="integrateOrder()">
                            <mat-icon class="material-icons-outlined">replay</mat-icon>
                        </button>
                    </div>

                </div>
                <!-- / APP TITLE -->
            </div>
            <!-- APP TITLE -->


        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <mat-tab-group fxFlex fxLayout="column">

                    <mat-tab label="Details">

                        <div class="order-details tab-content p-24" fusePerfectScrollbar>

                            <div *ngIf="order?.errorStatus?.errorDescription" class="section pb-48">
                                <div class="mat-elevation-z2 p-12" fxFlex="1 1 50" fxFlex.lt-md="0 0 100"
                                     fxFlex.lt-lg="1 1 60" fxFlex.gt-lg="1 1 30" fxLayout="row"
                                     style="border-left: 3px solid red;">
                                    <div fxFlex="0 0 20" fxLayout="column" fxLayoutAlign="center center">
                                        <mat-icon class="material-icons-outlined warn-fg" inline="true"
                                                  style="font-size: 40px;">cancel
                                        </mat-icon>
                                    </div>
                                    <div fxLayout="column" fxLayoutGap="12px">
                                        <h3 class="text-bold">Error</h3>


                                        <div fxLayout="row" fxLayoutGap="10px">
                                            <span class="secondary-text" fxFlex="0 0 30">Description</span>
                                            <span fxFlex>{{order?.errorStatus?.errorDescription}}</span>
                                        </div>

                                        <div fxLayout="row" fxLayoutGap="10px">
                                            <span class="secondary-text" fxFlex="0 0 30">Number of retries</span>
                                            <span fxFlex>{{order?.errorStatus?.retryCounter}}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="section pb-48" *ngIf="order?.warnings">
                                <div *ngFor="let warning of order.warnings">
                                    <div class="mat-elevation-z2 p-12" style="border-left:3px solid orange;"
                                         fxFlex="1 1 50" fxFlex.lt-md="0 0 100" fxFlex.lt-lg="1 1 60"
                                         fxFlex.gt-lg="1 1 30" fxLayout="row">
                                        <ng-container>
                                            <div fxFlex="1 0 20" fxLayout="column" fxLayoutAlign="center center">
                                                <mat-icon class="material-icons-outlined orange-fg" inline="true"
                                                          style="font-size: 40px;">warning
                                                </mat-icon>
                                            </div>
                                            <div fxLayout="column" fxFlex="1 1 80" fxLayoutGap="12px">
                                                <h3 class="text-bold">Warning</h3>
                                                <div fxLayout="row" fxLayoutGap="10px">
                                                    <span class="secondary-text">Description</span>
                                                    <span fxFlex>{{warning?.warningDescription}}</span>
                                                </div>
                                                <div fxLayout="row" fxLayoutGap="10px">
                                                    <span class="secondary-text">Created</span>
                                                    <span fxFlex>{{warning?.created?.date | date : ('DateFormat.date' |
                                                        translate)}}</span>
                                                </div>

                                            </div>
                                        </ng-container>
                                    </div>

                                </div>
                            </div>


                            <!-- CUSTOMER SECTION -->
                            <div class="section pb-48">
                                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="m-0 mr-16 secondary-text">account_circle</mat-icon>
                                    <div class="h2 secondary-text">Customer</div>
                                </div>
                                <sturm-order-customer-section [orderForm]="orderForm"
                                                              [customerPartyForm]="customerPartyForm"
                                                              (customerSectionSave)="onCustomerSectionSave()"></sturm-order-customer-section>
                            </div>
                            <!-- /CUSTOMER SECTION -->

                            <div class="section pb-48">

                                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="m-0 mr-16 secondary-text">style</mat-icon>
                                    <div class="h2 secondary-text">Tags</div>

                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <ng-container *ngIf="order && order.tags;else no_tags">
                                        <p *ngFor="let tag of order.tags" class="text-truncate text-boxed">{{tag}}</p>
                                    </ng-container>
                                    <ng-template #no_tags>
                                        <p class="text-truncate">No Tags</p>

                                    </ng-template>
                                </div>
                            </div>


                            <!-- STATUS SECTION  -->
                            <div class="section pb-48">
                                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="m-0 mr-16 secondary-text">access_time</mat-icon>
                                    <div class="h2 secondary-text">Order Status</div>
                                </div>
                                <div  fxLayout="column">
                                    <form [formGroup]="orderForm" class="p-24" fxLayout="row" fxLayout.lt-md="column"
                                          fxLayoutAlign="start center" fxLayoutAlign.lt-md="start" fxLayoutGap="15px">

                                        <mat-form-field appearance="outline" color="primary" fxFlex="40"
                                                        fxFlex.lt-md="1 1 40">
                                            <mat-label>Cancellation Reason</mat-label>
                                            <input matInput formControlName="cancellationReason">
                                            <input>
                                        </mat-form-field>

                                        <button (click)="cancelOrder()"
                                                [disabled]="!orderForm.get('cancellationReason').value?.trim()"
                                                color="accent" fxFlex.lt-md="1 1 40" mat-raised-button>
                                            Cancel Order
                                        </button>
                                    </form>
                                </div>
                                <sturm-order-status-section *permissionAny="ORDER_EDITORS"
                                                            [orderForm]="orderForm"></sturm-order-status-section>
                            </div>
                            <!-- /STATUS SECTION -->

                            <div class="section pb-48">

                                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="m-0 mr-16 secondary-text">attach_money</mat-icon>
                                    <div class="h2 secondary-text">Payment</div>

                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <h4 class="secondary-text">Method: </h4>
                                    <span>{{paymentMethod?.value}}</span>
                                </div>
                            </div>


                            <div class="section pb-48"
                                 *ngIf="order && (order.orderStatus === orderStatusEnum.SHIPPED || order.orderStatus === orderStatusEnum.TRACKING_ADDED)">

                                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="m-0 mr-16 secondary-text">local_shipping</mat-icon>
                                    <div class="h2 secondary-text">Shipment</div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <h4 class="secondary-text">Tracking number: </h4>
                                    <span>{{order?.trackingNumber}}</span>
                                </div>
                            </div>


                            <div class="section pb-48">

                                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="m-0 mr-16 secondary-text">description</mat-icon>
                                    <div class="h2 secondary-text">Identification numbers</div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <h4 class="secondary-text">External OrderId:</h4>
                                    <span>{{order?.externalOrderId | placeholder: '-'}}</span>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <h4 class="secondary-text">External Referenced OrderId:</h4>
                                    <span>{{order?.externalReferencedOrderId | placeholder: '-'}}</span>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <h4 class="secondary-text">External Order Number:</h4>
                                    <span>{{order?.externalOrderNumber | placeholder: '-'}}</span>
                                </div>
                               
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <h4 class="secondary-text">Shop Order ID:</h4>

                                   
                                </div>
                                <div  fxLayout="column">
                                    <div  class="p-24" fxLayout="row" fxLayout.lt-md="column"
                                          fxLayoutAlign="start center" fxLayoutAlign.lt-md="start" fxLayoutGap="15px">

                                        <mat-form-field appearance="outline" color="primary" fxFlex="40"
                                                        fxFlex.lt-md="1 1 40">
                                            
                                            <input [disabled]="!canUpdateShopOrderId()" matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="shopOrderId">
                                            <input>
                                        </mat-form-field>

                                        <button *ngIf="canUpdateShopOrderId()" (click)="updateShopOrderId()"
                                                [disabled]="shopOrderId == null || shopOrderId.trim()==''"
                                                color="accent" fxFlex.lt-md="1 1 40" mat-raised-button>
                                            Save 
                                        </button>
                                    </div>
                                </div>
                           
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <h4 class="secondary-text">Tracking Number:</h4>

                                   
                                </div>
                                <div  fxLayout="column">
                                    <form  class="p-24" fxLayout="row" fxLayout.lt-md="column"
                                          fxLayoutAlign="start center" fxLayoutAlign.lt-md="start" fxLayoutGap="15px">

                                        <mat-form-field appearance="outline" color="primary" fxFlex="40"
                                                        fxFlex.lt-md="1 1 40">
                                            
                                            <input [disabled]="!canAddTrackingNumber()" matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="trackingNumber">
                                            <input>
                                        </mat-form-field>

                                        <button *ngIf="canAddTrackingNumber()" (click)="addTrackingNumber()"
                                                [disabled]="trackingNumber == null || trackingNumber.trim()==''"
                                                color="accent" fxFlex.lt-md="1 1 40" mat-raised-button>
                                            Save
                                        </button>
                                    </form>
                                </div>
                           
                            </div>

                        </div>
                    </mat-tab>

                    <mat-tab label="Items">
                        <sturm-order-details-items-list [orderForm]="orderForm" (itemsSave)="onItemsSave()">
                        </sturm-order-details-items-list>
                    </mat-tab>
                    <mat-tab *ngIf="order && order?.logisticProcessingTime" label="Statistics">
                        <div class="order-details tab-content p-24" fusePerfectScrollbar>
                            <!-- Statistics SECTION -->
                            <div class="section pb-48">
                                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                                    <div class="h2 secondary-text">Logistic Processing Time: {{this.duration}}</div>
                                </div>

                            </div>
                            <!-- /Statistics SECTION -->
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>