import { ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Person } from '@yukawa/chain-base-angular-domain';
import { EMPTY, Observable, Subject } from 'rxjs';
import { delay, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations';
import {
    ConfirmDialogComponent,
} from '../../../shared/components/dialog-module/confirm-dialog/confirm-dialog.component';
import { AuthService } from '../../../shared/modules/security/service/auth.service';
import { Condition, Order, OrderParty, OrderStatus } from '../../base/core/domain';
import { SturmRealm } from '../../base/core/realm';
import { ConditionType, STATUS_SEQUENCE_DE, STATUS_SEQUENCE_US } from '../core/domain';
import { OrderService } from '../rest/order.service';
import { OrderUtils } from '../util';
import { OrderDetailService } from './order-detail.service';


@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    providers: [OrderDetailService]
})
export class OrderDetailComponent implements OnInit, OnDestroy {

    public ORDER_EDITORS = SturmRealm.ORDER_EDITORS;

    public pageType: 'new' | 'edit';
    public order: Order;
    public orderStatusEnum = OrderStatus;
    public paymentMethod: Condition;
    public orderForm: FormGroup;
    shopOrderId = null;
    trackingNumber = null;
    public customerPartyForm: FormGroup;
    prefixes = ["US", "DE", "IT"];
    public customer$: Observable<Person>;
    _STATUS_SEQUENCE_US = STATUS_SEQUENCE_US;
    _STATUS_SEQUENCE_DE = STATUS_SEQUENCE_DE;
    public fullName = OrderUtils.getFullName;
    canCancelOrder = false;
    private _unsubscribeAll: Subject<any>;
    duration: any;

    constructor(
        private zone: NgZone,
        private _orderService: OrderService,
        public _orderDetailService: OrderDetailService,
        private _activatedRoute: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private _translate: TranslateService,
        private _dialog: MatDialog,
        private _router: Router,
        public authService: AuthService,
        private _matSnackBar: MatSnackBar
    ) {
        this._unsubscribeAll = new Subject<any>();
    }

    ngOnInit(): void {

        this.createOrderForm();

        this.customer$ = this._orderDetailService.customer$.asObservable();

        this._activatedRoute.params.pipe(takeUntil(this._unsubscribeAll)).subscribe((params: any) => {
            this.pageType = params.id === '0' ? 'new' : 'edit';
            if (params.id !== '0') {
                this.canCancelOrder = false;
                this.loadOrder(params.id);
            }
        });

    }

    private loadOrder(orderId: string): void {
        this._orderService.loadOrder(orderId).subscribe(order => {
            this.onOrderLoaded(order);
            this.canCancelOrder = this._canCancelOrder();
        });
    }

    private onOrderLoaded(order: Order): void {
        this._orderDetailService.order$.next(order);
        this.order = order;
        this.shopOrderId = this.order.shopOrderId ? this.order.shopOrderId : null;
        this.trackingNumber = this.order.trackingNumber ? this.order.trackingNumber : null;
        if (order.logisticProcessingTime) {
            const date = new Date(0);
            date.setSeconds(order.logisticProcessingTime); // specify value for SECONDS here
            this.duration = date.toISOString().substr(11, 8);
        }
        this.orderForm.reset();

        this.initItemsFormArray(order);
        this.initPartiesFormArray(order);

        this.orderForm.patchValue(order);
        this.paymentMethod = OrderUtils.getCondition(order, ConditionType.TYPE_PAYMENT_METHOD);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
    }

    toggleExcludeOrderFromIntegration(value: MatCheckboxChange): void {

        if (!this.order) {
            throw new Error('Please create the order first.');
        }

        this._dialog.open(ConfirmDialogComponent, {
            data: {
                message: value?.checked ? 'Do you really want to exclude this order from integration?' :
                    'Do you really want to include this order for integration?'
            }
        })
            .afterClosed()
            .pipe(mergeMap(val => {

                if (val) {
                    return this._orderService
                        .merge({
                            orderId: this.order.orderId,
                            excludeFromIntegration: value.checked
                        } as Order);
                }

                value.source.checked = !value.checked;
                return EMPTY;
            }))
            .subscribe(o => {
                this._matSnackBar.open(value.checked ?
                    'Successfully excluded order from integration' :
                    'Successfully included order to integration', null,
                    {
                        panelClass: 'yuk-snackbar-success',
                        duration: 1300,
                        verticalPosition: 'top',
                        horizontalPosition: 'right'
                    });
            });
    }

    togglePreorder(value: MatCheckboxChange): void {
        if (!this.order) {
            throw new Error('Please create the order first.');
        }

        this._dialog.open(ConfirmDialogComponent, {
            data: {
                message: value.checked ? 'Do you really want to mark this order as a pre-order?' :
                    'Do you really want to remove the pre-order flag?'
            }
        }).afterClosed()
            .pipe(mergeMap(val => {
                if (val) {
                    return this._orderService
                        .merge({
                            orderId: this.order.orderId,
                            preOrder: value.checked
                        } as Order);
                }

                value.source.checked = !value.checked;

                return EMPTY;
            }))


            .subscribe(order => {
                this._matSnackBar.open('Successfully set preOrder=' + order['preOrder'], null,
                    {
                        panelClass: 'yuk-snackbar-success',
                        duration: 1300,
                        verticalPosition: 'top',
                        horizontalPosition: 'right'
                    });
            });
    }


    getOrderPartyPerson(role: string): Person {
        let party: OrderParty;
        if (this.order.parties) {
            party = this.order.parties.find(val => val.role === role);
        }
        return party ? party.person : null;
    }

    removeErrors(): void {
        this._dialog.open(ConfirmDialogComponent, { data: { message: 'Do you really want to remove the error status?' } })
            .afterClosed()
            .pipe(mergeMap(val => {
                return val ? this._orderService.merge({
                    orderId: this.order.orderId,
                    errorStatus: { retryCounter: 0 }
                }) : EMPTY;
            }))
            .pipe(tap(o => {
                this._matSnackBar.open('Successfully removed errors.', null,
                    {
                        panelClass: 'yuk-snackbar-success',
                        duration: 1300,
                        verticalPosition: 'top',
                        horizontalPosition: 'right'
                    });
            }), delay(1500), tap(o => {
                this.loadOrder(o.orderId);
            }))
            .subscribe();
    }

    deleteOrder(): void {
        this._dialog.open(ConfirmDialogComponent, { data: { message: 'Do you really want to delete this order?' } })
            .afterClosed()
            .pipe(
                mergeMap(val => {
                    return val ? this._orderService.delete(this.order.orderId) : EMPTY;
                }),
                tap(val => {
                    this._matSnackBar.open('Successfully deleted order ' + this.order.orderId, null,
                        {
                            panelClass: 'yuk-snackbar-success',
                            duration: 2500,
                            verticalPosition: 'top',
                            horizontalPosition: 'right'
                        });
                }),
                delay(1000)
            )
            .subscribe(o => {
                this._router.navigate(['../'], { relativeTo: this._activatedRoute });
            });
    }

    integrateOrder(): void {
        this._dialog.open(ConfirmDialogComponent, { data: { message: 'Do you really want to re-integrate this order?' } })
            .afterClosed()
            .pipe(
                mergeMap(val => {
                    return val ? this._orderService.synchronizeOrder(this.order.orderId) : EMPTY;
                }),
                tap(() => {
                    this._matSnackBar.open('Successfully re-integrated order ' + this.order.orderId, null,
                        {
                            panelClass: 'yuk-snackbar-success',
                            duration: 2500,
                            verticalPosition: 'top',
                            horizontalPosition: 'right'
                        });
                })
            )
            .subscribe(
                () => {
                    this.loadOrder(this.order.orderId);
                },
                error => {
                    this._matSnackBar.open(error?.error?.text, null,
                        {
                            panelClass: 'yuk-snackbar-error',
                            duration: 3500,
                            verticalPosition: 'top',
                            horizontalPosition: 'right'
                        });
                });
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Forms

    createOrderForm(): void {
        this.orderForm = this._formBuilder.group({
            orderId: [null, null],
            customerId: [null, null],
            state: [null, null],
            orderStatus: [null, null],
            cancellationReason: [null, null],
            excludeFromIntegration: [false, null],
            preOrder: [null, null],
            items: this._formBuilder.array([]),
            parties: this._formBuilder.array([])
        });
    }

    getLineItemForm(): FormGroup {
        return this._formBuilder.group({
            materialId: [null, null],
            bcSku: [null, null], // BigCommerce SKU to keep, as it might be a subject of rewrite
            quantity: [null, null],
            price: [null, null],
            name: [null, null],
            conditions: [null, null],
            components: [null, null],
            options: [null, null],
            gtin: [null, null],
            bigCommerceOrderProductId: [null, null]
        });

    }

    getOrderPartyForm(): FormGroup {
        return this._formBuilder.group({
            role: [],
            partyId: [],
            person: this.getPersonForm()
        });
    }

    getPersonForm(): FormGroup {
        return this._formBuilder.group({
            firstName: [],
            lastName: [],
            companyName: [],
            shortName: [],
            email: [],
            phoneNumber: [],
            mobile: [],
            role: [],
            vatNumber: [],
            title: [],
        });
    }

    private initItemsFormArray(order: Order): void {
        (this.orderForm.get('items') as FormArray).clear(); // clear first
        order.items.forEach(item => {
            (this.orderForm.get('items') as FormArray).push(this.getLineItemForm());
        });
    }

    private initPartiesFormArray(order: Order): void {
        (this.orderForm.get('parties') as FormArray).clear(); // clear first
        if (order.parties) {
            order.parties.forEach(party => {
                const fg: FormGroup = this.getOrderPartyForm();
                if (party.role === 'CUSTOMER') {
                    this.customerPartyForm = fg;
                }
                (this.orderForm.get('parties') as FormArray).push(fg);
            });
        }
    }

    onCustomerSectionSave(): void {
        this.loadOrder(this.order.orderId);
    }

    onItemsSave(): void {
        this.loadOrder(this.order.orderId);
    }

    cancelOrder(): void {
        if (!this.order) {
            throw new Error('Please create the order first.');
        }

        const _cancellationReason: string = this.orderForm.get('cancellationReason').value;

        this._orderService
            .cancel({ orderId: this.order.orderId, cancellationReason: _cancellationReason })
            .subscribe(val => {
                this._matSnackBar.open('Order Cancelled successfully', null,
                    { panelClass: 'yuk-snackbar-success', duration: 1300 });
                this.loadOrder(this.order.orderId);
                console.debug('Order is cancelled');
            });
    }

    _canCancelOrder(): boolean {
        let _type = this.prefixes.find(x => this.order.orderId.includes(x));

        if (!this.authService.hasRole("ROLE_ORDER_CANCEL")) {
            return false;
        }

        if (_type == "US") {
            return this._STATUS_SEQUENCE_US[this.order.orderStatus] == undefined ? false : (this.order.orderStatus != "CANCELLED" && this._STATUS_SEQUENCE_US[this.order.orderStatus] < this._STATUS_SEQUENCE_US["COMMISSIONED"]);
        } else {
            return this._STATUS_SEQUENCE_DE[this.order.orderStatus] == undefined ? false : (this.order.orderStatus != "CANCELLED" && this._STATUS_SEQUENCE_DE[this.order.orderStatus] < this._STATUS_SEQUENCE_DE["COMMISSIONED"]);
        }
    }

    updateShopOrderId() {
        this._orderService.updateShopOrderId(this.order.orderId, this.shopOrderId).subscribe(res => {
            this._matSnackBar.open(res["messages"][0]["text"], null,
            {
                panelClass: '',
                duration: 2000,
                verticalPosition: 'top',
                horizontalPosition: 'right'
            });
        })
    }
    addTrackingNumber() {
        this._orderService.addTrackingNumber(this.order.orderId, this.trackingNumber).subscribe(res => {
            this._matSnackBar.open(res["messages"][0]["text"], null,
            {
                panelClass: '',
                duration: 2000,
                verticalPosition: 'top',
                horizontalPosition: 'right'
            });
        })
    }
    canAddTrackingNumber() {
        return this.order && ([this.orderStatusEnum.SHIPPING_CONFIRMED, this.orderStatusEnum.TRACKING_ADDED, this.orderStatusEnum.SHIPMENT_INFO_ADDED, this.orderStatusEnum.DELIVERY_INFO_ADDED, this.orderStatusEnum.PARTIAL_DELIVERY_INFO_ADDED, this.orderStatusEnum.DELIVERY_INFO_CONFIRMED, this.orderStatusEnum.SHIPMENT_CREATED_IN_BIGCOMMERCE, this.orderStatusEnum.PARTIAL_SHIPMENT_CREATED_IN_BIGCOMMERCE, this.orderStatusEnum.READY_FOR_TRACKING_ADDED].includes(this.order.orderStatus))
    }

    canUpdateShopOrderId() {
        return this.order && ([this.orderStatusEnum.READ_FROM_BIG_COMMERCE, this.orderStatusEnum.CUSTOMER_INTEGRATED, this.orderStatusEnum.ENHANCED].includes(this.order.orderStatus))
    }

    isStatusGreater(_status_de: string, _status_us: string): boolean {
        let _type = this.prefixes.find(x => this.order.orderId.includes(x));
        if (_type == "US") {
            return this._STATUS_SEQUENCE_US[this.order.orderStatus] == undefined ? false : (this._STATUS_SEQUENCE_US[this.order.orderStatus] >= this._STATUS_SEQUENCE_US[_status_us]);
        } else {
            return this._STATUS_SEQUENCE_DE[this.order.orderStatus] == undefined ? false : (this._STATUS_SEQUENCE_DE[this.order.orderStatus] > this._STATUS_SEQUENCE_DE[_status_de]);
        }
    }
}
