import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from "../service/auth.service";

@Directive({selector: '[permissionExcept]'})
export class PermissionExceptDirective implements OnInit {
    constructor(
        private templateRef: TemplateRef<any>,
        private authService: AuthService,
        private viewContainer: ViewContainerRef
    ) {
    }

    permittedRoles: string[];

    @Input() set permissionExcept(permittedRoles: string[]) {
        this.permittedRoles = permittedRoles;
    }

    ngOnInit() {
        if (!this.authService.hasAnyRole(this.permittedRoles)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }

    }

}
