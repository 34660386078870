import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./components/login/login.component";
import {AuthguardService} from "./shared/modules/security/guard/authguard.service";

const routes: Routes = [
    {
        path: '',
        redirectTo: 'orders',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'orders',
        loadChildren: () => import('./sturm/order/sturm-order.module').then(m => m.SturmOrderModule),
        canActivate: [AuthguardService],
        data: {}
    },
    {
        path: 'logistics',
        loadChildren: () => import('./sturm/logistics-report/logistics-report.module').then(m => m.LogisticsReportModule),
        canActivate: [AuthguardService],
        data: {
            expectedRoles: ["ROLE_ADMIN", "ROLE_REPORT_READ"]
        }
    },
    {
        path: 'settings',
        loadChildren: () => import('./sturm/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthguardService],
        data: {
            expectedRoles: ["ROLE_ADMIN", "ROLE_SETTING_EDIT"]
        }
    }
];


@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true,
        scrollPositionRestoration: 'disabled',
        preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {


}
