import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

import {ConfigService, RestAspect} from '@yukawa/chain-base-angular-client';
import {RoleInfo} from "@yukawa/chain-security-angular-core";

@Injectable({
    providedIn: 'root'
})
export class RoleService extends RestAspect {

    roles: RoleInfo[] = [];

    constructor(protected http: HttpClient, configService: ConfigService) {
        super(http, configService);
    }


    getAllRoles(): Observable<any[]> {
        return this.http.get<RoleInfo[]>(`${this.formatServiceUrl('/all')}`);
    }

    protected formatServiceUrl(path: string = '') {
        return this.configService.formatUrl("mainUrl") + "/roles" + path;
    }

}

