import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from '../../modules/security/service/auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor
{

    constructor(public auth: TokenService)
    {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {

        if ((req.url.endsWith('/auth/refresh'))) {
            return next.handle(this.addToken(req, this.auth.token.refresh_token));
        }

        if (this.auth.token.access_token) {
            const cloned = req.clone({
                headers: req.headers.set("Authorization",
                    "Bearer " + this.auth.token.access_token)
            });
            return next.handle(cloned);
        } else {
            return next.handle(req);
        }
    }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + token
            }
        });

    }
}


// import { Injectable } from '@angular/core';
// import {
//     HttpRequest,
//     HttpHandler,
//     HttpEvent,
//     HttpInterceptor, HttpErrorResponse, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent
// } from '@angular/common/http';
// import { AuthService } from './auth.service';
// import { BehaviorSubject, Observable, throwError } from 'rxjs';
// import { catchError, switchMap, filter, take, finalize } from "rxjs/operators";

// @Injectable()
// export class AuthInterceptor implements HttpInterceptor {
//         isRefreshingToken: boolean = false;
//     tokenSubject: BehaviorSubject<string> = new BehaviorSubject<any>(null);

//     constructor(public auth: AuthService) { }

//     addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
//         return req.clone({
//             setHeaders: {
//                 Authorization: 'Bearer ' + token
//             }
//         });
//     }

//     intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
//         if ((request.url.indexOf("refresh") > 0) || (request.url.indexOf("revokeToken") > 0)) { // are we intercepting a refresh token request?
//             return next.handle(this.addToken(request, this.auth.token.refreshToken));
//         }

//         if (this.auth.isLoggedIn) {

//             return next.handle(this.addToken(request, this.auth.token.accessToken))
//                 .pipe(
//                     catchError((error: HttpErrorResponse) => {
//                         if (error.status == 401) {
//                             return this.handle401Error(request, next);
//                         } else {
//                             throwError(error);
//                         }
//                     })
//                 );
//         }


//         return next.handle(request);
//     }


//     handle401Error(req: HttpRequest<any>, next: HttpHandler) {

//         if (!this.isRefreshingToken) {

//             this.isRefreshingToken = true;

//             // Reset here so that the following requests wait until the token
//             // comes back from the refreshToken call.
//             this.tokenSubject.next(null);

//             return this.auth.refreshToken().pipe(
//                 switchMap((newToken: any) => {
//                     if (newToken) {
//                         this.tokenSubject.next(newToken);

//                         this.auth.token.accessExpiration = newToken.accessExpiration;
//                         this.auth.token.accessToken = newToken.accessToken;

//                         return next.handle(this.addToken(req, newToken.accessToken));
//                     }


//                 }),
//                 finalize(() => {
//                     this.isRefreshingToken = false;
//                 }));
//         } else {
//             return this.tokenSubject.pipe(
//                 filter(token => token != null),
//                 take(1),
//                 switchMap(token => {
//                     return next.handle(this.addToken(req, token));
//                 }));
//         }

//     }


// }
