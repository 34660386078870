import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderTableComponent} from "./order-table/order-table.component";
import {AppMaterialModule} from "../../shared/modules/material/app.material.module";
import {TranslateModule} from "@ngx-translate/core";
import {FuseSharedModule} from "../../../@fuse/shared.module";
import {MatToolbarModule} from "@angular/material/toolbar";
import {FuseSearchBarModule} from "../../../@fuse/components";
import {OrderDetailComponent} from './order-detail/order-detail.component';
import {Route, RouterModule} from "@angular/router";
import {AuthguardService} from "../../shared/modules/security/guard/authguard.service";
import {SharedPipesModule} from "../../shared/pipes/shared-pipes.module";
import {ChipSelectModule} from "../../shared/components/chip-select/chip-select.module";
import {DialogModule} from "../../shared/components/dialog-module/dialog.module";
import {ConfirmDialogComponent} from "../../shared/components/dialog-module/confirm-dialog/confirm-dialog.component";
import { CustomerTableComponent } from './order-detail/customer-section/customer-table/customer-table.component';
import { StatusSectionComponent } from './order-detail/status-section/status-section.component';
import { CustomerSectionComponent } from './order-detail/customer-section/customer-section.component';
import { ItemsListComponent } from './order-detail/items-list/items-list.component';
import {SecurityDirectivesModule} from "../../shared/modules/security/directives/security-directives.module";


export const routes: Route[] = [
    {
        path: '',
        canActivate: [AuthguardService],
        children: [
            {path: '', component: OrderTableComponent, pathMatch: 'full'},
            {

                path: 'orders', children: [
                    {path: '', component: OrderTableComponent},
                    {path: ':id', component: OrderDetailComponent}

                ]
            },

        ]
    }
];

@NgModule({
    declarations: [
        OrderTableComponent,
        OrderDetailComponent,
        CustomerTableComponent,
        StatusSectionComponent,
        CustomerSectionComponent,
        ItemsListComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                children: routes
            }
        ]),
        AppMaterialModule,
        TranslateModule,
        FuseSharedModule,
        MatToolbarModule,
        FuseSearchBarModule,
        SharedPipesModule,
        ChipSelectModule,
        DialogModule,
        SecurityDirectivesModule
    ],
    entryComponents: [ConfirmDialogComponent]
})
export class SturmOrderModule {
}
