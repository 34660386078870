import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PermissionExceptDirective} from "./permissionexcept.directive";
import {PermissionEditDirective} from "./permissionedit.directive";
import {PermissionIfAllDirective} from "./permissionifall.directive";
import {HasPermissionDirective} from './has-permission.directive';
import {PermissionAnyDirective} from "./permissionany.directive";


@NgModule({
    declarations: [
        PermissionAnyDirective,
        PermissionExceptDirective,
        PermissionEditDirective,
        PermissionIfAllDirective,
        HasPermissionDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PermissionAnyDirective,
        PermissionExceptDirective,
        PermissionEditDirective,
        PermissionIfAllDirective
    ]
})
export class SecurityDirectivesModule {
}
