<h1 mat-dialog-title>
    <mat-icon class="yellow-800-fg">
        warning_amber
    </mat-icon>
    <h2 class="secondary-text" [innerHTML]="data?.message"></h2>
</h1>

<mat-dialog-actions>
    <button mat-button color="accent" type="button" [mat-dialog-close]="false">{{'CMD.NO' | translate}}</button>
    <button mat-button color="accent" type="submit" [mat-dialog-close]="true">{{'CMD.YES' | translate}}</button>
</mat-dialog-actions>