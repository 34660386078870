import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Pager} from "@yukawa/chain-base-angular-domain";
import {Sort} from "@angular/material/sort";

@Injectable({
    providedIn: 'root'
})
export class TableStateService {

    public _tableFilters: Object;

    constructor() {
        this._tableFilters = {};
    }

    /**
     * table filter
     */
    get tableFilters(): Object {
        return this._tableFilters;
    }


    /**
     * Sets the table status to dictionary
     *
     * @param tableId
     * @param filter
     * @param pager
     * @param sort
     */
    public setStatus(tableId, filter: any, pager: Pager, sort: Sort): void {
        this._tableFilters[tableId] = {
            filter: filter,
            pager: pager,
            sort: sort
        }

        this._tableFilters = _.cloneDeep(this._tableFilters)
    }

    /**
     * Returns the filter object stored for the given table id
     * @param tableId
     */
    public getFilterValue(tableId: string): Object {
        return this._tableFilters[tableId];
    }


}
