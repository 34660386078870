import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'placeholder',
    pure: true

})
export class PlaceholderPipe implements PipeTransform {

    transform(value: string, placeholder: string): unknown {
        if (!value) {
            return placeholder;
        }

        return value;
    }

}
